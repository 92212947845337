/* import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import FormContent from './FormContent'; */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SelectLocation from "../dropdown";
import CircleProgess from "../progressBar";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";

Banner01.propTypes = {};
const STATICTITLE_URL = "/data/job_title_suggestions/";
function Banner01(props) {
  const access = localStorage.getItem("access");
  const [jobs, setJobs] = useState([]);
  const [titlename, setTitlename] = useState([]);
  const initialValues = {
    keyword: "",
  };
  const onSubmit = async (values) => {
    try {
      const response = await axios.get(
        `https://api.myzonehub.com/jobs/?keyword=${values.keyword}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        }
      );
      // Pass filter values to parent component
      //props.applyFilters(values);
      console.log(response.data);
      setJobs(response.data);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const fetchTitle = async (query) => {
    try {
      const response = await axios.get(STATICTITLE_URL, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json",
        },
        params: { query },
      });

      const data = response.data;
      setTitlename(data);
      console.log();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <section className="tf-slider sl3 over-flow-hidden">
      <div className="tf-container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="content wow fadeInUp">
              <div className="heading">
                <h2 className="text-white">Find the job that fits your life</h2>
                <p className="text-white">
                  Resume-Library is a true performance-based job board. Enjoy
                  custom hiring products and access to up to 10,000 new resume
                  registrations daily, with no subscriptions or user licences.
                </p>
              </div>
              <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-sl">
                      <form action="/job-list-sidebar">
                        <div className="row-group-search home1 st">
                          <div className="form-group-1">
                            <span className="icon-search search-job"></span>
                            <input
                              type="text"
                              list="titleList"
                              name="keyword"
                              id="keyword"
                              placeholder="job title, key words or company"
                              onChange={(e) => {
                                formik.handleChange(e);
                                fetchTitle(e.target.value);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.keyword}
                            />
                            <datalist id="titleList">
                              {titlename.map((titleoption) => (
                                <option
                                  key={titleoption.id}
                                  value={titleoption.title}
                                  id={titleoption.id}
                                />
                              ))}
                            </datalist>
                          </div>
                          <div className="form-group-2">
                            <span className="icon-map-pin"></span>
                            <SelectLocation />
                          </div>
                          <div className="form-group-4">
                            <button type="submit" className="btn btn-find">
                              Find Jobs
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </form>
                )}
              </Formik>

              <ul className="list-category text-white">
                <li>
                  <Link to="#">Designer</Link>
                </li>
                <li className="current">
                  <Link to="#">Developer</Link>
                </li>
                <li>
                  <Link to="#">Tester</Link>
                </li>
                <li>
                  <Link to="#">Writing</Link>
                </li>
                <li>
                  <Link to="#">Project Manager</Link>
                </li>
              </ul>
            </div>
          </div>
          {/*  <div className="col-lg-4">
            <div className="wd-review-job thumb2 widget-counter tf-sl3">
              <div className="thumb">
                <div className="box1">
                  <img
                    src={require("../../assets/images/review/thumb5.png")}
                    alt="images"
                  />
                  <div className="markk ani3">
                    <img
                      src={require("../../assets/images/review/shape3.png")}
                      alt="images"
                    />
                  </div>
                </div>
                <div className="box2">
                  <img
                    src={require("../../assets/images/review/thumb6.png")}
                    alt="images"
                  />
                  <div className="markk ani3">
                    <img
                      src={require("../../assets/images/review/shape4.png")}
                      alt="images"
                    />
                  </div>
                </div>
              </div>
              <div className="tes-box ani5">
                <div className="client-box">
                  <div className="avt">
                    <img
                      src={require("../../assets/images/review/client.jpg")}
                      alt="images"
                    />
                    <div className="badge"></div>
                  </div>
                  <div className="content">
                    <h6 className="number wrap-counter">1M+ recruiters</h6>
                    <div className="des">Happpy Candidates</div>
                  </div>
                </div>
              </div>
              <div className="icon1 ani3">
                <img
                  src={require("../../assets/images/review/icon2.png")}
                  alt="images"
                />
              </div>
              <div className="icon2 ani4">
                <img
                  src={require("../../assets/images/review/icon3.png")}
                  alt="images"
                />
              </div>
              <div className="icon3 ani6">
                <img
                  src={require("../../assets/images/review/icon11.png")}
                  alt="images"
                />
              </div>

              <div className="chart-box counter">
                <h6>Candidates</h6>
                <CircleProgess />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
export default Banner01;
