import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, FormLabel } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AlertTable from "./AlertTable";





MyJobalert.propTypes = {};

function MyJobalert(props) {


  return (
    <>
      <FormLabel>
        {/* <Col xs={12} md={12}>
          <h6 className="mt-4">Build Your Resume:</h6>
        </Col> */}
       {/*  <div className="form-candidate mt-4"> */}
          <Row>
            <Col xs={12} md={12}>
              <div className="form_heading">Manage Jobs Alerts</div>
            </Col>
            <Col xs={12} md={12}>
              <div className="ip p-2">
                <AlertTable />
              </div>
            </Col>
          </Row>
       {/*  </div> */}
      </FormLabel>
    </>
  );
}

export default MyJobalert;
