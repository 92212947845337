import React from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";
import RecruiterProfile from "./pages/RecruiterProfile";
import Home_v1 from "./pages/Home_v1";
import Home_v2 from "./pages/Home_v2";
import Home_v3 from "./pages/Home_v3";
import Home_v4 from "./pages/Home_v4";
import Home_v5 from "./pages/Home_v5";
import Home_v6 from "./pages/Home_v6";
import Home_v7 from "./pages/Home_v7";
import Home_v8 from "./pages/Home_v8";
import Home_v9 from "./pages/Home_v9";
import Home_v10 from "./pages/Home_v10";
import Joblist_v1 from "./pages/Joblist_v1";
import Joblist_v2 from "./pages/Joblist_v2";
import Joblist_v3 from "./pages/Joblist_v3";
import Joblist_v4 from "./pages/Joblist_v4";
import Joblist_v5 from "./pages/Joblist_v5";
import Joblist_v6 from "./pages/Joblist_v6";
import Joblist_v7 from "./pages/Joblist_v7";
import Joblist_v8 from "./pages/Joblist_v8";
import Joblist_v9 from "./pages/Joblist_v9";
import Joblist_v10 from "./pages/Joblist_v10";
import Jobsingle_v1 from "./pages/Jobsingle_v1";
import Jobsingle_v2 from "./pages/Jobsingle_v2";
import Employer_v1 from "./pages/Employer_v1";
import Employer_v2 from "./pages/Employer_v2";
import Employer_v3 from "./pages/Employer_v3";
import Employer_v4 from "./pages/Employer_v4";
import Employer_v5 from "./pages/Employer_v5";
import Employer_v6 from "./pages/Employer_v6";
import Employer_v7 from "./pages/Employer_v7";
import Employersingle_v1 from "./pages/Employersingle_v1";
import Employersingle_v2 from "./pages/Employersingle_v2";
import EmployerReview from "./pages/EmployerReview";
import Employernotfound from "./pages/Employernotfound";
import Employerdashboard from "./pages/Employerdashboard";
import Candidates_v1 from "./pages/Candidates_v1";
import Candidates_v2 from "./pages/Candidates_v2";
import Candidates_v3 from "./pages/Candidates_v3";
import Candidates_v4 from "./pages/Candidates_v4";
import Candidates_v5 from "./pages/Candidates_v5";
import Candidates_v6 from "./pages/Candidates_v6";
import Candidates_v7 from "./pages/Candidates_v7";
import SampleCV from "./pages/SampleCV";
import SampleCVdetails from "./pages/SampleCVdetails";
import SampleCVslidebar from "./pages/SampleCVslidebar";
import Candidatesingle_v1 from "./pages/Candidatesingle_v1";
import Candidatesingle_v2 from "./pages/Candidatesingle_v2";
import Blog_v1 from "./pages/Blog_v1";
import Blog_v2 from "./pages/Blog_v2";
import Blog_v3 from "./pages/Blog_v3";
import Blogsingle_v1 from "./pages/Blogsingle_v1";
import Blogsingle_v2 from "./pages/Blogsingle_v2";
import Blogsingle_v3 from "./pages/Blogsingle_v3";
import Shop from "./pages/Shop";
import Shopsingle from "./pages/Shopsingle";
import Shoppingcart from "./pages/Shoppingcart";
import Checkout from "./pages/Checkout";
import AboutUs from "./pages/AboutUs";
import Faqs from "./pages/Faqs";
import Termsofuse from "./pages/Termsofuse";
import Pricing from "./pages/Pricing";
import Login from "./pages/Login";
import CreateAccount from "./pages/CreateAccount";
import ContactUs from "./pages/ContactUs";
import { useEffect } from "react";
import ScrollToTop from "./ScrollToTop";
import { useState } from "react";
import Preloader from "./components/preloader";
import Verification from "./components/RecruiterVerification/Verification";
import Layout from "./components/layout/Layout";
import Page404 from "./pages/Page404";
//import { AuthProvider } from "./context/AuthProvider";
import RequireAuth from "./components/requireauth/RequireAuth";
import ResendVerificationEmail from "./components/RecruiterVerification/ResendVerificationEmail";
import ResetPassword from "./pages/Passwordreset";
import RecruiterProfileEdit from "./components/RecruiterProfile/RecruiterProfileEdit";

import PostJob from "./components/RecruiterProfile/PostJob";
import RecruiterProfile2 from "./pages/RecruiterProfile2";
import NewJob from "./components/RecruiterProfile/NewJob";
import RecruiterNewJobPost from "./pages/RecruiterNewJobPost";
import EditRecruiterJobpage from "./pages/recruiter/EditrecruiterJobpage";
import EditRecruiterJob from "./components/RecruiterProfile/EditRecruiterJob";
import RecruiterProfileView from "./components/RecruiterProfile/RecruiterProfileView";
//jobseeker;
import JobSeekerDashboard from "./pages/jobseeker/dashboard";
import JobseekerEditProfile from "./pages/jobseeker/EditProfile";
import MyJobApplication from "./pages/jobseeker/MyJobApplication";
import MyFavouriteJobs from "./pages/jobseeker/FavouriteJobs";
import MyFollowings from "./pages/jobseeker/MyFollowing";
import MyJobAlert from "./pages/jobseeker/MyJobAlert";
import ViewPublicProfile from "./pages/jobseeker/PublicProfile";
import PrintResume from "./pages/jobseeker/PrintResume";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home_v1 />} />
        <Route path="/jobs_by_category/:title" element={<Joblist_v4 />} />
        <Route path="/jobdetails/:jobId" element={<Jobsingle_v1 />} />
        <Route path="/login" element={<Login />} />
        <Route path="/password/reset" element={<ResetPassword />} />
        <Route path="/createaccount" element={<CreateAccount />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/unauthorized" element={<Page404 />} />

        <Route
          path="/recruiter/verification/:uid/:token"
          element={<Verification />}
        />
        <Route
          path="/recruiter/resend_verification_email/"
          element={<ResendVerificationEmail />}
        />
        
        <Route element={<RequireAuth allowedRoles={["job_seeker"]} />}>
          <Route path="/home_v2" element={<Home_v2 />} />
          <Route path="/jobseeker/dashboard" element={<JobSeekerDashboard />} />
          <Route
            path="/jobseeker/my-profile"
            element={<JobseekerEditProfile />}
          />
          <Route
            path="/jobseeker/my-job-application"
            element={<MyJobApplication />}
          />
          <Route
            path="/jobseeker/my-favourite-jobs"
            element={<MyFavouriteJobs />}
          />
          <Route path="/jobseeker/my-followings" element={<MyFollowings />} />
          <Route path="/jobseeker/my-alerts" element={<MyJobAlert />} />
          <Route
            path="/jobseeker/view-public-profile"
            element={<ViewPublicProfile />}
          />
          <Route path="/jobseeker/resume" element={<PrintResume />} />
          <Route path="/job-list-sidebar" element={<Joblist_v3 />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["recruiter"]} />}>
          <Route path="/pricing" element={<Pricing />} />
          <Route
            path="/recruiterprofileedit"
            element={<RecruiterProfileEdit />}
          />
          <Route path="/postjob" element={<PostJob />} />
          <Route path="/newjob" element={<NewJob />} />
          <Route path="/recruiterprofile" element={<RecruiterProfile />} />

          <Route
            path="/editrecruiterjobpage"
            element={<EditRecruiterJobpage />}
          />
          <Route path="/jobs/edit/:jobId" element={<EditRecruiterJob />} />

          <Route path="/recruiternewjob" element={<RecruiterNewJobPost />} />
          <Route path="/recruiterprofile2" element={<RecruiterProfile2 />} />
          <Route
            path="/recruiterprofileview"
            element={<RecruiterProfileView />}
          />
        </Route>

        <Route
          element={<RequireAuth allowedRoles={["job_seeker", "recruiter"]} />}
        >
          <Route path="/all-Categories" element={<Joblist_v1 />} />
          <Route path="/blogsingle_v3" element={<Blogsingle_v3 />} />
          <Route path="/home_v3" element={<Home_v3 />} />
          <Route path="/home_v4" element={<Home_v4 />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/employerreview" element={<EmployerReview />} />
          <Route path="/employernotfound" element={<Employernotfound />} />
          <Route path="/employerdashboard" element={<Employerdashboard />} />
          <Route path="/samplecv" element={<SampleCV />} />
          <Route path="/samplecvdetails" element={<SampleCVdetails />} />
          <Route path="/samplecvslidebar" element={<SampleCVslidebar />} />
          <Route path="/candidatesingle_v1" element={<Candidatesingle_v1 />} />
          <Route path="/candidatesingle_v2" element={<Candidatesingle_v2 />} />
          <Route path="/blog_v1" element={<Blog_v1 />} />
          <Route path="/blog_v2" element={<Blog_v2 />} />
          <Route path="/blog_v3" element={<Blog_v3 />} />
          <Route path="/blogsingle_v1" element={<Blogsingle_v1 />} />
          <Route path="/blogsingle_v2" element={<Blogsingle_v2 />} />
          <Route path="/joblist_v7" element={<Joblist_v7 />} />
          <Route path="/joblist_v8" element={<Joblist_v8 />} />
          <Route path="/joblist_v9" element={<Joblist_v9 />} />
          <Route path="/joblist_v10" element={<Joblist_v10 />} />
          <Route path="/jobsingle_v2" element={<Jobsingle_v2 />} />
          <Route path="/employers_v5" element={<Employer_v5 />} />
          <Route path="/employers_v6" element={<Employer_v6 />} />
          <Route path="/employers_v7" element={<Employer_v7 />} />
          <Route path="/employersingle_v2" element={<Employersingle_v2 />} />
          <Route path="/candidates_v5" element={<Candidates_v5 />} />
          <Route path="/candidates_v6" element={<Candidates_v6 />} />
          <Route path="/candidates_v7" element={<Candidates_v7 />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/shopsingle" element={<Shopsingle />} />
          <Route path="/shoppingcart" element={<Shoppingcart />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/termsofuse" element={<Termsofuse />} />
          <Route path="/home_v5" element={<Home_v5 />} />

          <Route path="/job-grid" element={<Joblist_v2 />} />
          <Route path="/joblist_v5" element={<Joblist_v5 />} />

          <Route path="/employers_v1" element={<Employer_v1 />} />
          <Route path="/employers_v2" element={<Employer_v2 />} />
          <Route path="/employers_v3" element={<Employer_v3 />} />
          <Route path="/employers_v4" element={<Employer_v4 />} />
          <Route path="/employersingle_v1" element={<Employersingle_v1 />} />
          <Route path="/candidates_v1" element={<Candidates_v1 />} />
          <Route path="/candidates_v2" element={<Candidates_v2 />} />
          <Route path="/candidates_v3" element={<Candidates_v3 />} />
          <Route path="/candidates_v4" element={<Candidates_v4 />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/home_v6" element={<Home_v6 />} />
          <Route path="/home_v7" element={<Home_v7 />} />
          <Route path="/home_v8" element={<Home_v8 />} />
          <Route path="/home_v9" element={<Home_v9 />} />
          <Route path="/home_v10" element={<Home_v10 />} />
          <Route path="/joblist_v6" element={<Joblist_v6 />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
/* return (
    <Routes>
      <Route path="/" element={<Layout />}>
       
        <Route path="login" element={<Login />} />
        <Route path="createaccount" element={<CreateAccount />} />
        <Route path="/unauthorized" element={<Page404 />} />
        

       
        <Route element={<RequireAuth allowedRoles={["job_seeker"]} />}>
          <Route path="/home_v2" element={<Home_v2 />} />
          <Route path="/home_v3" element={<Home_v3 />} />
          <Route path="/home_v4" element={<Home_v4 />} />
          <Route path="/home_v5" element={<Home_v5 />} />
          <Route path="/joblist_v1" element={<Joblist_v1 />} />
          <Route path="/job-grid" element={<Joblist_v2 />} />
          <Route path="/job-list-sidebar" element={<Joblist_v3 />} />
          <Route path="/job-grid-sidebar" element={<Joblist_v4 />} />
          <Route path="/joblist_v5" element={<Joblist_v5 />} />
          <Route path="/jobsingle_v1" element={<Jobsingle_v1 />} />
          <Route path="/employers_v1" element={<Employer_v1 />} />
          <Route path="/employers_v2" element={<Employer_v2 />} />
          <Route path="/employers_v3" element={<Employer_v3 />} />
          <Route path="/employers_v4" element={<Employer_v4 />} />
          <Route path="/employersingle_v1" element={<Employersingle_v1 />} />
          <Route path="/candidates_v1" element={<Candidates_v1 />} />
          <Route path="/candidates_v2" element={<Candidates_v2 />} />
          <Route path="/candidates_v3" element={<Candidates_v3 />} />
          <Route path="/candidates_v4" element={<Candidates_v4 />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/faqs" element={<Faqs />} />
          
        </Route>
       
        <Route element={<RequireAuth allowedRoles={["recruiter"]} />}>
        <Route path="/recruiterprofile" element={<RecruiterProfile />} />
          <Route path="/home_v6" element={<Home_v6 />} />
          <Route path="/home_v7" element={<Home_v7 />} />
          <Route path="/home_v8" element={<Home_v8 />} />
          <Route path="/home_v9" element={<Home_v9 />} />
          <Route path="/home_v10" element={<Home_v10 />} />
          <Route path="/joblist_v6" element={<Joblist_v6 />} />
          <Route path="/joblist_v7" element={<Joblist_v7 />} />
          <Route path="/joblist_v8" element={<Joblist_v8 />} />
          <Route path="/joblist_v9" element={<Joblist_v9 />} />
          <Route path="/joblist_v10" element={<Joblist_v10 />} />
          <Route path="/jobsingle_v2" element={<Jobsingle_v2 />} />
          <Route path="/employers_v5" element={<Employer_v5 />} />
          <Route path="/employers_v6" element={<Employer_v6 />} />
          <Route path="/employers_v7" element={<Employer_v7 />} />
          <Route path="/employersingle_v2" element={<Employersingle_v2 />} />
          <Route path="/candidates_v5" element={<Candidates_v5 />} />
          <Route path="/candidates_v6" element={<Candidates_v6 />} />
          <Route path="/candidates_v7" element={<Candidates_v7 />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/shopsingle" element={<Shopsingle />} />
          <Route path="/shoppingcart" element={<Shoppingcart />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/termsofuse" element={<Termsofuse />} />
          <Route path="/pricing" element={<Pricing />} />
        </Route>

        <Route
          element={<RequireAuth allowedRoles={["job_seeker", "recruiter"]} />}
        >
          
          <Route path="/" element={<Home_v1 />} />
          <Route path="/employerreview" element={<EmployerReview />} />
          <Route path="/employernotfound" element={<Employernotfound />} />
          <Route path="/employerdashboard" element={<Employerdashboard />} />
          <Route path="/samplecv" element={<SampleCV />} />
          <Route path="/samplecvdetails" element={<SampleCVdetails />} />
          <Route path="/samplecvslidebar" element={<SampleCVslidebar />} />
          <Route path="/candidatesingle_v1" element={<Candidatesingle_v1 />} />
          <Route path="/candidatesingle_v2" element={<Candidatesingle_v2 />} />
          <Route path="/blog_v1" element={<Blog_v1 />} />
          <Route path="/blog_v2" element={<Blog_v2 />} />
          <Route path="/blog_v3" element={<Blog_v3 />} />
          <Route path="/blogsingle_v1" element={<Blogsingle_v1 />} />
          <Route path="/blogsingle_v2" element={<Blogsingle_v2 />} />
          <Route path="/blogsingle_v3" element={<Blogsingle_v3 />} />
        </Route>
      </Route>
    </Routes>
  ); */

/* let routes = useRoutes([
    { path: "/", element: <Home_v1 /> },
    
   
    { path: "/home_v2", element: <Home_v2 /> },
    { path: "/home_v3", element: <Home_v3 /> },
    { path: "/recruiterprofile", element: <RecruiterProfile /> },

    { path: "/home_v4", element: <Home_v4 /> },
    { path: "/home_v5", element: <Home_v5 /> },
    { path: "/home_v6", element: <Home_v6 /> },
    { path: "/home_v7", element: <Home_v7 /> },
    { path: "/home_v8", element: <Home_v8 /> },
    { path: "/home_v9", element: <Home_v9 /> },
    { path: "/home_v10", element: <Home_v10 /> },
    { path: "/joblist_v1", element: <Joblist_v1 /> },
    { path: "/job-grid", element: <Joblist_v2 /> },
    { path: "/job-list-sidebar", element: <Joblist_v3 /> },
    { path: "/job-grid-sidebar", element: <Joblist_v4 /> },
    { path: "/joblist_v5", element: <Joblist_v5 /> },
    { path: "/joblist_v6", element: <Joblist_v6 /> },
    { path: "/joblist_v7", element: <Joblist_v7 /> },
    { path: "/joblist_v8", element: <Joblist_v8 /> },
    { path: "/joblist_v9", element: <Joblist_v9 /> },
    { path: "/joblist_v10", element: <Joblist_v10 /> },
    { path: "/jobsingle_v1", element: <Jobsingle_v1 /> },
    { path: "/jobsingle_v2", element: <Jobsingle_v2 /> },
    { path: "/employers_v1", element: <Employer_v1 /> },
    { path: "/employers_v2", element: <Employer_v2 /> },
    { path: "/employers_v3", element: <Employer_v3 /> },
    { path: "/employers_v4", element: <Employer_v4 /> },
    { path: "/employers_v5", element: <Employer_v5 /> },
    { path: "/employers_v6", element: <Employer_v6 /> },
    { path: "/employers_v7", element: <Employer_v7 /> },
    { path: "/employersingle_v1", element: <Employersingle_v1 /> },
    { path: "/employersingle_v2", element: <Employersingle_v2 /> },
    { path: "/employerreview", element: <EmployerReview /> },
    { path: "/employernotfound", element: <Employernotfound /> },
    { path: "/employerdashboard", element: <Employerdashboard /> },
    { path: "/candidates_v1", element: <Candidates_v1 /> },
    { path: "/candidates_v2", element: <Candidates_v2 /> },
    { path: "/candidates_v3", element: <Candidates_v3 /> },
    { path: "/candidates_v4", element: <Candidates_v4 /> },
    { path: "/candidates_v5", element: <Candidates_v5 /> },
    { path: "/candidates_v6", element: <Candidates_v6 /> },
    { path: "/candidates_v7", element: <Candidates_v7 /> },
    { path: "/samplecv", element: <SampleCV /> },
    { path: "/samplecvdetails", element: <SampleCVdetails /> },
    { path: "/samplecvslidebar", element: <SampleCVslidebar /> },
    { path: "/candidatesingle_v1", element: <Candidatesingle_v1 /> },
    { path: "/candidatesingle_v2", element: <Candidatesingle_v2 /> },
    { path: "/blog_v1", element: <Blog_v1 /> },
    { path: "/blog_v2", element: <Blog_v2 /> },
    { path: "/blog_v3", element: <Blog_v3 /> },
    { path: "/blogsingle_v1", element: <Blogsingle_v1 /> },
    { path: "/blogsingle_v2", element: <Blogsingle_v2 /> },
    { path: "/blogsingle_v3", element: <Blogsingle_v3 /> },
    { path: "/shop", element: <Shop /> },
    { path: "/shopsingle", element: <Shopsingle /> },
    { path: "/shoppingcart", element: <Shoppingcart /> },
    { path: "/checkout", element: <Checkout /> },
    { path: "/aboutus", element: <AboutUs /> },
    { path: "/faqs", element: <Faqs /> },
    { path: "/termsofuse", element: <Termsofuse /> },
    { path: "/pricing", element: <Pricing /> },
    { path: "/login", element: <Login /> },
    { path: "/createaccount", element: <CreateAccount /> },
    { path: "/contactus", element: <ContactUs /> },
  ]);
  return routes;  */

/* const AppWrapper = () => {
  const [loading, setLoading] = useState(true);
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    // Simulate fetching user roles from the server
    // Use the actual logic to fetch roles from the response JSON
    const rolesFromServer = ["recruiter"]; // Replace this with actual roles from the response

    // Set user roles once roles are fetched
    setUserRoles(rolesFromServer);

    // Simulate loading delay
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <ScrollToTop />
          <App userRoles={userRoles} />
        </>
      ) : (
        <Preloader />
      )}
    </>
  );
};
export default AppWrapper; */
/* const AppWrapper = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {!loading ? (
        <Router>
        <>
          <ScrollToTop />
          <App />
        </>
      ) : (
         </Router>
        <Preloader />
      )}
    </>
  );
};
 */

{
  /* <Routes>
<Route path="/" element={<Layout />}>
   public routes 
  <Route path="login" element={<Login />} />
  <Route path="createaccount" element={<CreateAccount />} />
  <Route path="/" element={<Home_v1 />} />
   we want to protect these routes 
  <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
    <Route path="/home_v2" element={<Home_v2 />} />
    <Route path="/home_v3" element={<Home_v3 />} />
    <Route path="/home_v4" element={<Home_v4 />} />
    <Route path="/home_v5" element={<Home_v5 />} />
    <Route path="/home_v6" element={<Home_v6 />} />
    <Route path="/home_v7" element={<Home_v7 />} />
    <Route path="/home_v8" element={<Home_v8 />} />
    <Route path="/home_v9" element={<Home_v9 />} />
  </Route>

  <Route element={<RequireAuth allowedRoles={[ROLES.Editor]} />}>
    <Route path="/home_v10" element={<Home_v10 />} />
    <Route path="/joblist_v1" element={<Joblist_v1 />} />
    <Route path="/job-grid" element={<Joblist_v2 />} />
    <Route path="/job-list-sidebar" element={<Joblist_v3 />} />
  </Route>

  <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
    <Route path="/job-grid-sidebar" element={<Joblist_v4 />} />
    <Route path="/jobsingle_v1" element={<Jobsingle_v1 />} />
    <Route path="/jobsingle_v2" element={<Jobsingle_v2 />} />
  </Route>

  <Route
    element={<RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} />}
  >
    <Route path="/employerreview" element={<EmployerReview />} />
    <Route path="/employernotfound" element={<Employernotfound />} />
  </Route>
</Route>
</Routes>; */
}
/* return (
  <Router>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/createaccount" element={<CreateAccount />} />
      <Route path="/unauthorized" element={<Page404 />} />
      <Route path="/" element={<Home_v1 />} />

     
      <Route
        path="/home_v2"
        element={
          <ProtectedRoute element={<Home_v2 />} roles={["job_seeker"]} />
        }
      />
      <Route
        path="/home_v3"
        element={
          <ProtectedRoute element={<Home_v3 />} roles={["job_seeker"]} />
        }
      />
      <Route
        path="/home_v4"
        element={
          <ProtectedRoute element={<Home_v4 />} roles={["job_seeker"]} />
        }
      />

      <Route
        path="/home_v5"
        element={
          <ProtectedRoute element={<Home_v5 />} roles={["job_seeker"]} />
        }
      />
      <Route
        path="/joblist_v1"
        element={
          <ProtectedRoute element={<Joblist_v1 />} roles={["job_seeker"]} />
        }
      />
      <Route
        path="/job-grid"
        element={
          <ProtectedRoute element={<Joblist_v2 />} roles={["job_seeker"]} />
        }
      />
      <Route
        path="/job-list-sidebar"
        element={
          <ProtectedRoute element={<Joblist_v3 />} roles={["job_seeker"]} />
        }
      />
      <Route
        path="/job-grid-sidebar"
        element={
          <ProtectedRoute element={<Joblist_v4 />} roles={["job_seeker"]} />
        }
      />
      <Route
        path="/joblist_v5"
        element={
          <ProtectedRoute element={<Joblist_v5 />} roles={["job_seeker"]} />
        }
      />
      <Route
        path="/jobsingle_v1"
        element={
          <ProtectedRoute element={<Jobsingle_v1 />} roles={["job_seeker"]} />
        }
      />
      <Route
        path="/employers_v1"
        element={
          <ProtectedRoute element={<Employer_v1 />} roles={["job_seeker"]} />
        }
      />
      <Route
        path="/employers_v2"
        element={
          <ProtectedRoute element={<Employer_v2 />} roles={["job_seeker"]} />
        }
      />
      <Route
        path="/employers_v3"
        element={
          <ProtectedRoute element={<Employer_v3 />} roles={["job_seeker"]} />
        }
      />
      <Route
        path="/employers_v4"
        element={
          <ProtectedRoute element={<Employer_v4 />} roles={["job_seeker"]} />
        }
      />

      <Route
        path="/employersingle_v1"
        element={
          <ProtectedRoute
            element={<Employersingle_v1 />}
            roles={["job_seeker"]}
          />
        }
      />
      <Route
        path="/candidates_v1"
        element={
          <ProtectedRoute
            element={<Candidates_v1 />}
            roles={["job_seeker"]}
          />
        }
      />
      <Route
        path="/candidates_v2"
        element={
          <ProtectedRoute
            element={<Candidates_v2 />}
            roles={["job_seeker"]}
          />
        }
      />
      <Route
        path="/candidates_v3"
        element={
          <ProtectedRoute
            element={<Candidates_v3 />}
            roles={["job_seeker"]}
          />
        }
      />
      <Route
        path="/candidates_v4"
        element={
          <ProtectedRoute
            element={<Candidates_v4 />}
            roles={["job_seeker"]}
          />
        }
      />
      <Route
        path="/checkout"
        element={
          <ProtectedRoute element={<Checkout />} roles={["job_seeker"]} />
        }
      />
      <Route
        path="/aboutus"
        element={
          <ProtectedRoute element={<AboutUs />} roles={["job_seeker"]} />
        }
      />
      <Route
        path="/faqs"
        element={<ProtectedRoute element={<Faqs />} roles={["job_seeker"]} />}
      />
     
      <Route
        path="/recruiterprofile"
        element={
          <ProtectedRoute
            element={<RecruiterProfile />}
            roles={["recruiter"]}
          />
        }
      />
      <Route
        path="/home_v6"
        element={
          <ProtectedRoute element={<Home_v6 />} roles={["recruiter"]} />
        }
      />

      <Route
        path="/home_v7"
        element={
          <ProtectedRoute element={<Home_v7 />} roles={["recruiter"]} />
        }
      />

      <Route
        path="/home_v8"
        element={
          <ProtectedRoute element={<Home_v8 />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/home_v9"
        element={
          <ProtectedRoute element={<Home_v9 />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/home_v10"
        element={
          <ProtectedRoute element={<Home_v10 />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/joblist_v6"
        element={
          <ProtectedRoute element={<Joblist_v6 />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/joblist_v7"
        element={
          <ProtectedRoute element={<Joblist_v7 />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/joblist_v8"
        element={
          <ProtectedRoute element={<Joblist_v8 />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/joblist_v9"
        element={
          <ProtectedRoute element={<Joblist_v9 />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/joblist_v10"
        element={
          <ProtectedRoute element={<Joblist_v10 />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/jobsingle_v2"
        element={
          <ProtectedRoute element={<Jobsingle_v2 />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/employers_v5"
        element={
          <ProtectedRoute element={<Employer_v5 />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/employers_v6"
        element={
          <ProtectedRoute element={<Employer_v6 />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/employers_v7"
        element={
          <ProtectedRoute element={<Employer_v7 />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/employersingle_v2"
        element={
          <ProtectedRoute
            element={<Employersingle_v2 />}
            roles={["recruiter"]}
          />
        }
      />
      <Route
        path="/candidates_v5"
        element={
          <ProtectedRoute element={<Candidates_v5 />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/candidates_v6"
        element={
          <ProtectedRoute element={<Candidates_v6 />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/candidates_v7"
        element={
          <ProtectedRoute element={<Candidates_v7 />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/shop"
        element={<ProtectedRoute element={<Shop />} roles={["recruiter"]} />}
      />
      <Route
        path="/shopsingle"
        element={
          <ProtectedRoute element={<Shopsingle />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/shoppingcart"
        element={
          <ProtectedRoute element={<Shoppingcart />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/contactus"
        element={
          <ProtectedRoute element={<ContactUs />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/termsofuse"
        element={
          <ProtectedRoute element={<Termsofuse />} roles={["recruiter"]} />
        }
      />
      <Route
        path="/pricing"
        element={
          <ProtectedRoute element={<Pricing />} roles={["recruiter"]} />
        }
      />
    </Routes>
  </Router>
); */
