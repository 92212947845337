import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container as BootstrapContainer } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Stack from "react-bootstrap/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import axios from "../../api/axios";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import ButtonGroup from "react-bootstrap/ButtonGroup";

const RECRUITERJOBS_URL = "recruiter/jobs/";
const JOBEDITING_URL = "jobs/create_job/";
const PostJob = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const access = localStorage.getItem("access");

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchJobs = async () => {
    try {
      const response = await axios.get(RECRUITERJOBS_URL, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json",
        },
      });

      const { payload } = response.data;
      setJobs(payload);
      console.log("nand", response.data.payload.id);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);
  //const jobId =8
  //console.log("jobId",jobId)
  const JobPublish = async (id, status) => {
   // alert(status);
    try {
      const response = await axios.patch(
        JOBEDITING_URL,

        {
          id: id,
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("status", response.data.status);

      if (response.data.status === 200) {
        fetchJobs();
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        jobs.map((job) => (
          <section className="blog-details-section" key={job.id}>
            <div className="tf-container">
              <div className="candidate-form job-search-form inner-form-map style2">
                <form>
                  <BootstrapContainer style={{ marginBottom: "30px" }}>
                    <Row key={job.id}>
                      <Col xs={12} md={3}>
                        <strong>
                          <span>[{job.status}]</span> {job.title}
                        </strong>
                      </Col>
                      <Col xs={12} md={5}>
                        <p>
                          {job.location_type} · {job.location}
                        </p>
                      </Col>
                      <Col xs={5} md={1}>
                        <Link to={`/jobs/edit/${job.id}`}>
                          <Button variant="secondary">Edit</Button>
                        </Link>
                      </Col>

                      <Col xs={5} md={2} className="d-flex align-items-center">
                      {job.status === "published" ?  <Dropdown as={ButtonGroup}>
                          <Button variant="success"
                            onClick={() => JobPublish(job.id, "draft")}
                          >UnPublish</Button>

                          <Dropdown.Toggle
                            split
                            variant="success"
                            id="dropdown-split-basic"
                          />

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              {" "}
                              PUBLIC
                              <br /> visible on your career page and select free
                              and <br />
                              premium job boards
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              {" "}
                              USE INTERNALLY
                              <br /> visible to your Jobtex account members
                              only.Account
                              <br />
                              members can refer candidates or ask to join the
                              hiring <br /> team
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              {" "}
                              USE CONFIDENTIALLY
                              <br />
                              Only visible to account admins and member of the
                              hiring
                              <br />
                              team.other account members cannot refer candidates
                              or <br />
                              ask to join hiring team
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> :  <Dropdown as={ButtonGroup}>
                          <Button variant="success"
                            onClick={() => JobPublish(job.id, "published")}
                          >Publish</Button>

                          <Dropdown.Toggle
                            split
                            variant="success"
                            id="dropdown-split-basic"
                          />

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              {" "}
                              <strong>PUBLIC</strong>
                              <br /> visible on your career page and select free
                              and <br />
                              premium job boards
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              {" "}
                              <strong> USE INTERNALLY</strong>
                             
                              <br /> visible to your Jobtex account members
                              only.Account
                              <br />
                              members can refer candidates or ask to join the
                              hiring <br /> team
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              {" "}
                              <strong> USE CONFIDENTIALLY</strong>
                             
                              <br />
                              Only visible to account admins and member of the
                              hiring
                              <br />
                              team.other account members cannot refer candidates
                              or <br />
                              ask to join hiring team
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        }
                       
                        {/*  {job.status === "published" ? (
                          <Button
                            variant="success"
                            style={{ borderRadius: 0 }}
                            onClick={() => JobPublish(job.id, "job.status")}
                          >
                            UnPublish
                          </Button>
                        ) : (
                          <Button
                            variant="success"
                            style={{ borderRadius: 0 }}
                            onClick={() => JobPublish(job.id, job.status)}
                          >
                            Publish
                          </Button>
                        )} */}

                        {/*   <DropdownButton
                          id="dropdown-button-dark-example2"
                          variant="success"
                          title=""
                          //className="mt-2"
                          data-bs-theme="light"
                        >
                          <Dropdown.Item
                            href="#/action-1"
                            active
                            style={{ backgroundColor: "transparent" }}
                          ></Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            <p>
                              PUBLIC
                              <br /> visible on your career page and select free
                              and <br />
                              premium job boards
                            </p>
                          </Dropdown.Item>

                          <Dropdown.Divider />

                          <Dropdown.Item href="#/action-3">
                            <p>
                              USE INTERNALLY
                              <br /> visible to your Jobtex account members
                              only.Account
                              <br />
                              members can refer candidates or ask to join the
                              hiring <br /> team
                            </p>
                          </Dropdown.Item>

                          <Dropdown.Divider />
                          <Dropdown.Item href="#/action-4">
                            <p>
                              USE CONFIDENTIALLY
                              <br />
                              Only visible to account admins and member of the
                              hiring
                              <br />
                              team.other account members cannot refer candidates
                              or <br />
                              ask to join hiring team
                            </p>
                          </Dropdown.Item>
                        </DropdownButton> */}
                      </Col>
                      <Col xs={2} md={1}>
                        <div>
                          <IconButton
                            aria-controls="dropdown-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                          >
                            <MoreVertIcon />
                            {/*  <span>Click me</span> */}
                          </IconButton>

                          <Menu
                            id="dropdown-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            {/* Your dropdown content goes here */}
                            <MenuItem onClick={handleClose}>
                              {" "}
                              Preview job
                            </MenuItem>
                            <MenuItem onClick={handleClose}>Edit job</MenuItem>
                            <MenuItem onClick={handleClose}>Clone job</MenuItem>
                            <MenuItem onClick={handleClose}>Leave job</MenuItem>
                          </Menu>
                        </div>
                      </Col>
                    </Row>
                  </BootstrapContainer>

                  <Row className="align-items-center flex-md-nowrap">
                    <Col xs={12} md={2} className="form-group location">
                      <p>
                        <strong>Sourced</strong>
                      </p>
                    </Col>
                    <Col xs={12} md={2} className="form-group location">
                      <p>
                        <strong>Applied</strong>
                      </p>
                    </Col>
                    <Col xs={12} md={2} className="form-group location">
                      <p>
                        <strong>Phone Screen</strong>
                      </p>
                    </Col>
                    <Col xs={12} md={2} className="form-group location">
                      <p>
                        <strong>Assessment</strong>
                      </p>
                    </Col>
                    <Col xs={12} md={2} className="form-group location">
                      <p>
                        <strong>Interview</strong>
                      </p>
                    </Col>
                    <Col xs={12} md={2} className="form-group location">
                      <p>
                        <strong>Offer</strong>
                      </p>
                    </Col>
                    {/*  <Col xs={12} md={2} className="form-group location">
                      <p>
                        <strong>Hired</strong>
                      </p>
                    </Col> */}
                  </Row>
                </form>
              </div>
            </div>
          </section>
        ))
      )}
    </>
  );
};

export default PostJob;
