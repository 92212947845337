import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/system";

const AlertTable = () => {
 

  const [data, setData] = useState([
    { id: 1, title: "RTT", name: "John Doe", age: 30 },
    { id: 2, title: "CSR", name: "Jane Smith", age: 25 },
    { id: 3, title: "OPO", name: "Bob Johnson", age: 40 },
  ]);



  const handleDelete = (id) => {
    // Add your delete logic here
    setData(data.filter((item) => item.id !== id));
  };
  const GreenButton = styled(Button)({
    "&:hover": {
      backgroundColor: "#148160",
    },
  });
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Alert Title</TableCell>
            <TableCell>Created On</TableCell>
            <TableCell>Added Date</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.age}</TableCell>
              <TableCell>
              
                <button
                  className="tf-btn-submit style-2"
                  variant="primary"
                  color="primary"
                  onClick={() => handleDelete(row.id)}
                >
                 <DeleteIcon />
                </button>
              
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AlertTable;
