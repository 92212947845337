import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import RangeOne from "../range-slider";
import RangeTwo from "../range-slider/RangleTwo";
import SelectLocation from "../dropdown";
import Dropdown from "react-dropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import Select from "react-select";

Sidebar.propTypes = {};
//const JOBLISTING_URL = "jobs/";
const STATICTITLE_URL = "/data/job_title_suggestions/";
const STATICLOCATION_URL = "/data/location/";

const STATICEXPERIENCE_URL = "/data/experience/";
const STATICEDUCATION_URL = "/data/education/";
const STATICJOBTYPE_URL = "/data/job_type/";

function Sidebar(props) {
  const access = localStorage.getItem("access");

  const [titlename, setTitlename] = useState([]);
  const [getjobType, setGetJobType] = useState([]);
  const [showLocationInput, setShowLocationInput] = useState(true);
  const [getLocation, setGetLocation] = useState([]);

  const [getExperience, setGetExperience] = useState([]);
  const [getEducation, setGetEducation] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);

  const initialValues = {
    keyword: "",
    location_type: "",
    location: "",
    job_type: "",
    education: "",
    experience: "",
    vacancies: "",
    salary: "",
    days_posted: "",
  };
  const onSubmit = async (values) => {
    props.handleClick(values,getLocation,getjobType,getExperience,getEducation)
    
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });
  const fetchTitle = async (query) => {
    try {
      const response = await axios.get(STATICTITLE_URL, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json",
        },
        params: { query },
      });

      const data = response.data;
      setTitlename(data);
      console.log();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTitle(formik.values.keyword);
  }, [formik.values.keyword]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(STATICLOCATION_URL, {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        });
        setGetLocation(response.data);
        console.log("Location Type:", formik.values.location_type);
      } catch (error) {
        console.error("Error fetching static data:", error);
      }
    };

    fetchData();
  }, [access, formik.values.location_type]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(STATICEXPERIENCE_URL, {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        });
        setGetExperience(response.data);
      } catch (error) {
        console.error("Error fetching static data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(STATICEDUCATION_URL, {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        });
        setGetEducation(response.data);
      } catch (error) {
        console.error("Error fetching static data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(STATICJOBTYPE_URL, {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        });
        setGetJobType(response.data);
      } catch (error) {
        console.error("Error fetching static data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="widget-filter st2  style-scroll po-sticky">
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit} className="wd-form-rating">
            <div className="group-form">
              <label className="title">job title</label>
              <div className="group-input search-ip">
                <button>
                  <i className="icon-search"></i>
                </button>
                <input
                  type="text"
                  list="titleList"
                  name="keyword"
                  id="keyword"
                  onChange={(e) => {
                    formik.handleChange(e);
                    fetchTitle(e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.keyword}
                />
                <datalist id="titleList">
                  {titlename.map((titleoption) => (
                    <option
                      key={titleoption.id}
                      value={titleoption.title}
                      id={titleoption.id}
                    />
                  ))}
                </datalist>
              </div>
            </div>
            <div className="group-form">
              <label className="title">Workplace</label>

              <div className="group-input">
                <Field
                  as="select"
                  id="location_type"
                  name="location_type"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setShowLocationInput(e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.location_type}
                >
                  <option value="0" key="0" label="Select an option" />
                  {getLocation.map((locationOption) => (
                    <option
                      key={locationOption.id}
                      value={locationOption.name}
                      label={locationOption.name}
                    />
                  ))}
                </Field>
              </div>
            </div>
            <div className="group-form">
              <label className="title">Location</label>
              <div className="group-input">
                <input
                  type="text"
                  id="location"
                  name="location"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.location}
                />
                {formik.touched.location && formik.errors.location && (
                  <div style={{ color: "red" }}>{formik.errors.location}</div>
                )}
              </div>
            </div>
            <div className="group-form">
              <label htmlFor="job_type"> Employment type</label>
              <div className="group-input">
                <Field
                  as="select"
                  id="job_type"
                  name="job_type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.job_type}
                >
                  <option value="" label="Select an option" />
                  {getjobType.map((jobtypeOption) => (
                    <option
                      key={jobtypeOption.id}
                      value={jobtypeOption.name}
                      label={jobtypeOption.name}
                    />
                  ))}
                </Field>
                {formik.touched.job_type && formik.errors.job_type && (
                  <div style={{ color: "red" }}>{formik.errors.job_type}</div>
                )}
              </div>
            </div>
            <div className="wrap-input">
              <label className="title" htmlFor="education">
                Education
              </label>
              <Field
                as="select"
                id="education"
                name="education"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.education}
              >
                <option value="" label="Select an option" />
                {getEducation.map((educationOption) => (
                  <option
                    key={educationOption.id}
                    value={educationOption.level}
                    label={educationOption.level}
                  />
                ))}
              </Field>
              {formik.touched.education && formik.errors.education && (
                <div style={{ color: "red" }}>{formik.errors.education}</div>
              )}
            </div>
            <div className="group-form">
              <label className="title" htmlFor="experience">
                Experience
              </label>
              <Field
                as="select"
                id="experience"
                name="experience"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.experience}
              >
                <option value="" label="Select an option" />
                {getExperience.map((experienceOption) => (
                  <option
                    key={experienceOption.id}
                    value={experienceOption.level}
                    label={experienceOption.level}
                  />
                ))}
              </Field>
              {formik.touched.experience && formik.errors.experience && (
                <div style={{ color: "red" }}>{formik.errors.experience}</div>
              )}
            </div>
            <div className="group-form">
              <label className="title">Days Posted</label>
              <div className="group-input">
                <input
                  type="text"
                  id="days_posted"
                  name="days_posted"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.days_posted}
                />
                {formik.touched.days_posted && formik.errors.days_posted && (
                  <div style={{ color: "red" }}>
                    {formik.errors.days_posted}
                  </div>
                )}
              </div>
            </div>
            <div className="group-form">
              <label className="title">Salary</label>
              <div className="group-input">
                <input
                  type="text"
                  id="salary"
                  name="salary"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.salary}
                />
                {formik.touched.salary && formik.errors.salary && (
                  <div style={{ color: "red" }}>{formik.errors.salary}</div>
                )}
              </div>
            </div>
            <div className="group-form">
              <label className="title">Vacancy</label>
              <div className="group-input">
                <input
                  type="text"
                  id="vacancy"
                  name="vacancy"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.vacancy}
                />
                {formik.touched.vacancy && formik.errors.vacancy && (
                  <div style={{ color: "red" }}>{formik.errors.vacancy}</div>
                )}
              </div>
            </div>
            <RangeTwo title="Salary:" />
            <RangeOne miles="Miles" title="Radius:" />
            <button type="submit">Find Jobs</button>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default Sidebar;
