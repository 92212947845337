import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MapSingle from "../components/map/MapSingle";
import Gotop from "../components/gotop";
import lo1 from "../assets/images/logo-company/cty4.png";
import { Rating } from "react-simple-star-rating";
import StarProgress from "../components/progressBar/StarProgress";
import Video from "../components/popup/Video";
import Gallery from "../components/popup/Gallery";
import { Collapse } from "react-collapse";
import logo from "../assets/images/logo.png";
import Header4 from "../components/header/Header4";
import { Modal, Button, FormLabel } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import RecruiterProfileEdit from "../components/RecruiterProfile/RecruiterProfileEdit";
import RecruiterProfileView from "../components/RecruiterProfile/RecruiterProfileView";

const marKers = [
  {
    id: 1,
    title: "Rockstar Games New York",
    name: "Senior UI/UX Designer",
    address: "Las Vegas, NV 89107, USA",
    longitude: -74.00122,
    latitude: 40.71023,
    img: lo1,
  },
];

const RecruiterProfileDetails_URL = "/recruiter/recruiter_profile/";

RecruiterProfile.propTypes = {};

function RecruiterProfile(props) {
  const [profile_id, setprofileId] = useState("");
  const [formData, setFormData] = useState(null);
  const access = localStorage.getItem("access");
  const UserName = localStorage.getItem("username");
  console.log("UserName", UserName);

  const validationSchema = Yup.object().shape({
    // Define your form fields and their validation rules
    organization_name: Yup.string().required("Organization name is required"),
    registration_number: Yup.string().required(
      "Registration number is required"
    ),
  });

  const initialValues = {
    profile_id: "",
    organization_name: "",
    registration_number: "",
    logo: null,
    website: "",
    contact_number: "",
    entity_type: "",
    parent_org_name: "",
    num_employees: "",
    about: "",
    key_business_activities: "",
    industry_sector: "",
    org_address: "",
    parent_org_address: "",
    billing_address: "",
    primary_contact_name: "",
    primary_contact_email: "",
    primary_contact_tel: "",
    client_entity_type: "",
    client_org_name: "",
    client_num_employees: "",
    client_org_website: "",
    client_org_industry: "",
    client_org_address: "",
    client_primary_contact_email: "",
    client_primary_contact_phone: "",
    contract_copy_with_client: null,
    registration_number: "",
    photo_logo: null,
    website: "",
    contact_number: "",
    billing_address2: "",
  };

  const onSubmit = async (values) => {
    try {
      // Assuming you have an endpoint for updating data
      const response = await axios.patch(
        RecruiterProfileDetails_URL,
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "multipart/form-data",
          },
        },

        {
          organization_name: values.organization_name,
          registration_number: values.registration_number,
          logo: values.logo,
          website: values.website,
          contact_number: values.contact_number,
          entity_type: values.entity_type,
          parent_org_name: values.parent_org_name,
          num_employees: values.num_employees,
          about: values.about,
          key_business_activities: values.key_business_activities,
          industry_sector: values.industry_sector,
          org_address: values.org_address,
          parent_org_address: values.parent_org_address,
          billing_address: values.billing_address,
          primary_contact_name: values.primary_contact_name,
          primary_contact_email: values.primary_contact_email,
          primary_contact_tel: values.primary_contact_tel,
          client_entity_type: values.client_entity_type,
          client_org_name: values.client_org_name,
          client_num_employees: values.client_num_employees,
          client_org_website: values.client_org_website,
          client_org_industry: values.client_org_industry,
          client_org_address: values.client_org_address,
          client_primary_contact_email: values.client_primary_contact_email,
          client_primary_contact_phone: values.client_primary_contact_phone,
          photo_logo: values.photo_logo,
          billing_address2: values.billing_address2,
        }
      );
      // const profile_id = response.data.agency_profile_id;
      //setprofileId(profile_id);
      //localStorage.setItem("agency_profile_id", profile_id);
      //console.log("profile_id",profile_id)
      setFormData(values);
      // Handle the response as needed
      console.log(response.data);
      const profileId = response.data.organization_profile_id
      setprofileId(profileId)

    } catch (error) {
      // Handle errors
      console.error("Error updating data:", error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const [rating, setRating] = useState(0);
  const progressRef = useRef();
  const [targetHeight, setTargetHeight] = useState(0);
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);

  const handleRating = (rate) => {
    setRating(rate);
  };
  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  useEffect(() => {
    if (progressRef?.current) {
      const offsetHeight = progressRef?.current?.offsetTop;
      setTargetHeight(offsetHeight);
    }
  }, [progressRef]);

  return (
    <>
      <div className="menu-mobile-popup">
        <div className="modal-menu__backdrop" onClick={handleMobile}></div>
        <div className="widget-filter">
          <div className="mobile-header">
            <div id="logo" className="logo">
              <Link to="/">
                <img className="site-logo" src={logo} alt="Image" />
              </Link>
            </div>
            <Link className="title-button-group" onClick={handleMobile}>
              <i className="icon-close"></i>
            </Link>
          </div>

          <Tabs className="tf-tab">
            <TabList className="menu-tab">
              <Tab className="user-tag">Menu</Tab>
              <Tab className="user-tag">Categories</Tab>
            </TabList>

            <div className="content-tab">
              <TabPanel className="header-ct-center menu-moblie animation-tab">
                <div className="nav-wrap">
                  <nav className="main-nav mobile">
                    <ul id="menu-primary-menu" className="menu">
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("home");
                          }}
                        >
                          Home
                        </Link>
                        <Collapse isOpened={toggle.key === "home"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "home" ? "block" : "none"
                              }`,
                            }}
                          >
                            <li className="menu-item menu-item-mobile">
                              <Link to="/">Home Page 01 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v2">Home Page 02 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v3">Home Page 03 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v4">Home Page 04 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v5">Home Page 05 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v6">Home Page 06 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v7">Home Page 07 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v8">Home Page 08 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v9">Home Page 09 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v10">Home Page 10 </Link>
                            </li>
                          </ul>
                        </Collapse>
                      </li>

                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("job");
                          }}
                        >
                          Find jobs
                        </Link>
                        <Collapse isOpened={toggle.key === "job"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "job" ? "block" : "none"
                              }`,
                            }}
                          >
                            <li className="menu-item menu-item-mobile">
                              <Link to="/joblist_v1">List Layout</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/job-grid">Grid Layout</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/job-list-sidebar">List Sidebar</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/job-grid-sidebar">Grid Sidebar</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/joblist_v5">
                                List Sidebar Fullwidth
                              </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/joblist_v6">
                                Grid Sidebar Fullwidth
                              </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/joblist_v7">Top Map</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/joblist_v8">Top Map Sidebar</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/joblist_v9">Half Map - V1</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/joblist_v10">Half Map - V2</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/jobsingle_v1">Jobs Single - V1</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/jobsingle_v2">Jobs Single - V2</Link>
                            </li>
                          </ul>
                        </Collapse>
                      </li>

                      <li className="menu-item menu-item-has-children-mobile current-item">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("employers");
                          }}
                        >
                          Employers
                        </Link>
                        <Collapse isOpened={toggle.key === "employers"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "employers" ? "block" : "none"
                              }`,
                            }}
                          >
                            <li className="menu-item">
                              <Link to="/employers_v1">List Layout</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/employers_v2">Grid Layout</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/employers_v3">List Sidebar</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/employers_v4">Grid Sidebar</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/employers_v5">Full Width</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/employers_v6">Top Map</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/employers_v7">Half Map</Link>
                            </li>
                            <li className="menu-item current-item">
                              <Link to="/employersingle_v1">
                                Employers Single - V1
                              </Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/employersingle_v2">
                                Employers Single - V2
                              </Link>
                            </li>

                            <li className="menu-item">
                              <Link to="/employerreview">
                                Employers Reviews
                              </Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/employernotfound">
                                Employers Not Found
                              </Link>
                            </li>
                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("candidate");
                          }}
                        >
                          Candidates
                        </Link>
                        <Collapse isOpened={toggle.key === "candidate"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "candidate" ? "block" : "none"
                              }`,
                            }}
                          >
                            <li className="menu-item menu-item-mobile">
                              <Link to="/candidates_v1">List Layout</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/candidates_v2">Grid Layout</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/candidates_v3">List Sidebar</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/candidates_v4">Top Map</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/candidates_v5">Half Map</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/candidates_v6">No Available V1</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/candidates_v7">No Available V2</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/candidatesingle_v1">
                                Candidate Single - V1
                              </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/candidatesingle_v2">
                                Candidate Single - V2
                              </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/samplecv">Sample CV</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/samplecvslidebar">
                                Sample CV Sidebar
                              </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/samplecvdetails">CV Details</Link>
                            </li>
                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("blog");
                          }}
                        >
                          Blog
                        </Link>
                        <Collapse isOpened={toggle.key === "blog"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "blog" ? "block" : "none"
                              }`,
                            }}
                          >
                            <li className="menu-item menu-item-mobile">
                              <Link to="/blog_v1">Blog List </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/blog_v2">Blog Grid</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/blog_v3">Blog Masonry</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/blogsingle_v1">Blog Details - V1</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/blogsingle_v2">Blog Details - V2</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/blogsingle_v3">
                                Blog Details Sidebar
                              </Link>
                            </li>
                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("pages");
                          }}
                        >
                          Pages
                        </Link>
                        <Collapse isOpened={toggle.key === "pages"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "pages" ? "block" : "none"
                              }`,
                            }}
                          >
                            <li className="menu-item menu-item-mobile">
                              <Link to="/aboutus">About Us</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/faqs">FAQS</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/termsofuse">Terms Of Use</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/pricing">Pricing</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/shop">Shop List</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/shoppingcart">Shopping Cart</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/shopsingle">Shop Single</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/checkout">Checkout</Link>
                            </li>

                            <li className="menu-item menu-item-mobile">
                              <Link to="/login">Login</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/createaccount">Create Account</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/contactus">Contact Us</Link>
                            </li>
                          </ul>
                        </Collapse>
                      </li>
                    </ul>
                  </nav>
                </div>
              </TabPanel>

              <TabPanel className="categories animation-tab">
                <div className="sub-categorie-mobile">
                  <ul className="pop-up">
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-1"></span>Design &
                        Creative
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-8"></span>Digital
                        Marketing
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-2"></span>Development &
                        IT
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-3"></span>Music & Audio
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-4"></span>Finance &
                        Accounting
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-5"></span>Programming &
                        Tech
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-6"></span>Video &
                        Animation
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-7"></span>Writing &
                        translation
                      </Link>
                    </li>
                  </ul>
                </div>
              </TabPanel>
            </div>
          </Tabs>

          <div className="header-customize-item button">
            <Link to="/">Upload Resume</Link>
          </div>

          <div className="mobile-footer">
            <div className="icon-infor d-flex aln-center">
              <div className="icon">
                <span className="icon-call-calling">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </span>
              </div>
              <div className="content">
                <p>Need help? 24/7</p>
                <h6>
                  <Link to="tel:0123456678">001-1234-88888</Link>
                </h6>
              </div>
            </div>
            <div className="wd-social d-flex aln-center">
              <ul className="list-social d-flex aln-center">
                <li>
                  <Link to="#">
                    <i className="icon-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-linkedin2"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-pinterest"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-instagram1"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-youtube"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Header4 clname="actEm2" handleMobile={handleMobile} />
      <section className="single-job-thumb">
        <img
          src={require("../assets/images/review/singlejob.jpg")}
          alt="images"
        />
      </section>

      <section className="form-sticky fixed-space">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="wd-job-author stc-em">
                <div className="inner-job-left">
                  <img
                    src={require("../assets/images/logo-company/cty4.png")}
                    alt="Jobtex"
                    className="logo-company"
                  />
                  <div className="content">
                    <h3>
                      <Link to="#">{UserName}</Link>&nbsp;
                      <span className="icon-bolt"></span>
                    </h3>
                    <div className="job-info">
                      <span className="icon-map-pin"></span>
                      <span>Las Vegas, NV 89107, USA</span>
                    </div>
                    <div className="group-btn">
                      <button className="tf-btn">Follow</button>
                      {/*  <button className="tf-btn">2 job openings</button> */}
                    </div>
                  </div>
                </div>
                <div className="inner-job-right">
                  <span className="icon-share2"></span>
                  <div className="group-btn">
                    {/*  <button className="tf-btn-submit btn-popup">
                      Write a review
                    </button>
                    <button className="tf-btn">Message</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="inner-employer-section">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-8">
              <Tabs className="job-article tf-tab single-job single-employer">
                <TabList className="menu-tab">
                  <Tab className="ct-tab">Recruiter Profile Details</Tab>
                  <Tab className="ct-tab">Jobs (2)</Tab>
                  <Tab className="ct-tab">reviews</Tab>
                </TabList>
                <div className="content-tab">
                  <TabPanel className="inner-content animation-tab">
                    <FormLabel>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                      >
                        {(formik) => (
                          <form onSubmit={formik.handleSubmit}>
                            <Row>
                              <Col xs={12} md={8}>
                                <div className="form-rating-heading">
                                  <h6>
                                    Common fields of Agency and Organization
                                    recruiter type
                                  </h6>
                                </div>
                              </Col>
                              <Col md={2}>
                                <div className="form-rating-heading">
                                  <RecruiterProfileEdit /* profile_id={profile_id} formData={formData} */
                                  />
                                </div>
                              </Col>
                              <Col md={2}>
                                <div className="form-rating-heading">
                                  <RecruiterProfileView  profile_id={profile_id} 
                                  />
                                </div>
                              </Col>

                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="organization_name">
                                    Organization Name:
                                  </label>
                                  <input
                                    type="text"
                                    // placeholder="EX: Boston University"
                                    id="organization_name"
                                    name="organization_name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.organization_name}
                                  />
                                  {formik.touched.organization_name &&
                                    formik.errors.organization_name && (
                                      <div>
                                        {formik.errors.organization_name}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="registration_number">
                                    Register Number:
                                  </label>
                                  <input
                                    type="text"
                                    // placeholder="EX: Bachelor's"
                                    id="registration_number"
                                    name="registration_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.registration_number}
                                  />
                                  {formik.touched.registration_number &&
                                    formik.errors.registration_number && (
                                      <div>
                                        {formik.errors.registration_number}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="website">Website:</label>
                                  <input
                                    type="text"
                                    //placeholder="EX: Business"
                                    id="website"
                                    name="website"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.website}
                                  />
                                  {formik.touched.website &&
                                    formik.errors.website && (
                                      <div>{formik.errors.website}</div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={12}>
                                <div className="ip p-2">
                                  <label htmlFor="logo">Logo:</label>

                                  <input
                                    type="file"
                                    id="logo"
                                    name="logo"
                                    onChange={(event) => {
                                      formik.setFieldValue(
                                        "logo",
                                        event.currentTarget.files[0]
                                      );
                                      console.log("onChange", event);
                                    }}
                                    onBlur={formik.handleBlur}
                                  />
                                  {formik.touched.logo &&
                                    formik.errors.logo && (
                                      <div>{formik.errors.logo}</div>
                                    )}
                                </div>
                              </Col>

                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="contact_number">
                                    Contact Number:
                                  </label>
                                  <input
                                    type="text"
                                    id="contact_number"
                                    name="contact_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.contact_number}
                                  />
                                  {formik.touched.contact_number &&
                                    formik.errors.contact_number && (
                                      <div>{formik.errors.contact_number}</div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="entity_type">
                                    Entity Type:
                                  </label>
                                  <input
                                    type="text"
                                    id="entity_type"
                                    name="entity_type"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.entity_type}
                                  />
                                  {formik.touched.entity_type &&
                                    formik.errors.entity_type && (
                                      <div>{formik.errors.entity_type}</div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="parent_org_name">
                                    Parent Organization Name:
                                  </label>
                                  <input
                                    type="text"
                                    id="parent_org_name"
                                    name="parent_org_name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.parent_org_name}
                                  />
                                  {formik.touched.parent_org_name &&
                                    formik.errors.parent_org_name && (
                                      <div>{formik.errors.parent_org_name}</div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="num_employees">
                                    Employees Number:
                                  </label>
                                  <input
                                    type="text"
                                    id="num_employees"
                                    name="num_employees"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.num_employees}
                                  />
                                  {formik.touched.num_employees &&
                                    formik.errors.num_employees && (
                                      <div>{formik.errors.num_employees}</div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={12}>
                                <div className="ip p-2">
                                  <label htmlFor="about">About:</label>
                                  <Field
                                    as="textarea"
                                    id="about"
                                    name="about"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.about}
                                    rows="4" // Set the number of rows for the textarea
                                  />
                                  {formik.touched.about &&
                                    formik.errors.about && (
                                      <div>{formik.errors.about}</div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="key_business_activities">
                                    Key Business Activities:
                                  </label>
                                  <input
                                    type="text"
                                    id="key_business_activities"
                                    name="key_business_activities"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={
                                      formik.values.key_business_activities
                                    }
                                  />
                                  {formik.touched.num_employees &&
                                    formik.errors.num_employees && (
                                      <div>{formik.errors.num_employees}</div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="industry_sector">
                                    Industry Sector:
                                  </label>
                                  <input
                                    type="text"
                                    id="industry_sector"
                                    name="industry_sector"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.industry_sector}
                                  />
                                  {formik.touched.industry_sector &&
                                    formik.errors.industry_sector && (
                                      <div>{formik.errors.industry_sector}</div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="org_address">
                                    Organization Address:
                                  </label>
                                  <input
                                    type="text"
                                    id="org_address"
                                    name="org_address"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.org_address}
                                  />
                                  {formik.touched.org_address &&
                                    formik.errors.org_address && (
                                      <div>{formik.errors.org_address}</div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="parent_org_address">
                                    Parent Organization Address:
                                  </label>
                                  <input
                                    type="text"
                                    id="parent_org_address"
                                    name="parent_org_address"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.parent_org_address}
                                  />
                                  {formik.touched.parent_org_address &&
                                    formik.errors.parent_org_address && (
                                      <div>
                                        {formik.errors.parent_org_address}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="billing_address">
                                    Billing Address:
                                  </label>
                                  <input
                                    type="text"
                                    id="billing_address"
                                    name="billing_address"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.billing_address}
                                  />
                                  {formik.touched.billing_address &&
                                    formik.errors.billing_address && (
                                      <div>{formik.errors.billing_address}</div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="primary_contact_name">
                                    Primary Contact Name:
                                  </label>
                                  <input
                                    type="text"
                                    id="primary_contact_name"
                                    name="primary_contact_name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.primary_contact_name}
                                  />
                                  {formik.touched.primary_contact_name &&
                                    formik.errors.primary_contact_name && (
                                      <div>
                                        {formik.errors.primary_contact_name}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="primary_contact_email">
                                    Primary Contact Email:
                                  </label>
                                  <input
                                    type="text"
                                    id="primary_contact_email"
                                    name="primary_contact_email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.primary_contact_email}
                                  />
                                  {formik.touched.primary_contact_email &&
                                    formik.errors.primary_contact_email && (
                                      <div>
                                        {formik.errors.primary_contact_email}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="primary_contact_tel">
                                    Primary Contact Telephone:
                                  </label>
                                  <input
                                    type="text"
                                    id="primary_contact_tel"
                                    name="primary_contact_tel"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.primary_contact_tel}
                                  />
                                  {formik.touched.primary_contact_tel &&
                                    formik.errors.primary_contact_tel && (
                                      <div>
                                        {formik.errors.primary_contact_tel}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={12}>
                                <div className="form-rating-heading">
                                  <h6>
                                    Common fields of Agency and Individual
                                    Recruiter type
                                  </h6>
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="client_entity_type">
                                    Client Entry Type:
                                  </label>
                                  <input
                                    type="text"
                                    id="client_entity_type"
                                    name="client_entity_type"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.client_entity_type}
                                  />
                                  {formik.touched.client_entity_type &&
                                    formik.errors.client_entity_type && (
                                      <div>
                                        {formik.errors.client_entity_type}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="client_org_name">
                                    Client Organization Name:
                                  </label>
                                  <input
                                    type="text"
                                    id="client_org_name"
                                    name="client_org_name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.client_org_name}
                                  />
                                  {formik.touched.client_org_name &&
                                    formik.errors.client_org_name && (
                                      <div>{formik.errors.client_org_name}</div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="client_num_employees">
                                    Client Number Employees:
                                  </label>
                                  <input
                                    type="text"
                                    id="client_num_employees"
                                    name="client_num_employees"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.client_num_employees}
                                  />
                                  {formik.touched.client_num_employees &&
                                    formik.errors.client_num_employees && (
                                      <div>
                                        {formik.errors.client_num_employees}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="client_org_website">
                                    Client Organization Website:
                                  </label>
                                  <input
                                    type="text"
                                    id="client_org_website"
                                    name="client_org_website"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.client_org_website}
                                  />
                                  {formik.touched.client_org_website &&
                                    formik.errors.client_org_website && (
                                      <div>
                                        {formik.errors.client_org_website}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="client_org_industry">
                                    Client Organization Industry:
                                  </label>
                                  <input
                                    type="text"
                                    id="client_org_industry"
                                    name="client_org_industry"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.client_org_industry}
                                  />
                                  {formik.touched.client_org_industry &&
                                    formik.errors.client_org_industry && (
                                      <div>
                                        {formik.errors.client_org_industry}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="client_org_address">
                                    Client Organization Address:
                                  </label>
                                  <input
                                    type="text"
                                    id="client_org_address"
                                    name="client_org_address"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.client_org_address}
                                  />
                                  {formik.touched.client_org_address &&
                                    formik.errors.client_org_address && (
                                      <div>
                                        {formik.errors.client_org_address}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="client_primary_contact_email">
                                    Client Primary Contact Email:
                                  </label>
                                  <input
                                    type="text"
                                    id="client_primary_contact_email"
                                    name="client_primary_contact_email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={
                                      formik.values.client_primary_contact_email
                                    }
                                  />
                                  {formik.touched
                                    .client_primary_contact_email &&
                                    formik.errors
                                      .client_primary_contact_email && (
                                      <div>
                                        {
                                          formik.errors
                                            .client_primary_contact_email
                                        }
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="client_primary_contact_phone">
                                    Client Primary Contact Phone:
                                  </label>
                                  <input
                                    type="text"
                                    id="client_primary_contact_phone"
                                    name="client_primary_contact_phone"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={
                                      formik.values.client_primary_contact_phone
                                    }
                                  />
                                  {formik.touched
                                    .client_primary_contact_phone &&
                                    formik.errors
                                      .client_primary_contact_phone && (
                                      <div>
                                        {
                                          formik.errors
                                            .client_primary_contact_phone
                                        }
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={12}>
                                <div className="ip p-2">
                                  <label htmlFor="contract_copy_with_client">
                                    Contract Copy With Client:
                                  </label>
                                  <input
                                    type="file"
                                    id="contract_copy_with_client"
                                    name="contract_copy_with_client"
                                    onChange={(event) => {
                                      formik.setFieldValue(
                                        "contract_copy_with_client",
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                  />
                                  {formik.touched.contract_copy_with_client &&
                                    formik.errors.contract_copy_with_client && (
                                      <div>
                                        {
                                          formik.errors
                                            .contract_copy_with_client
                                        }
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={12}>
                                <div className="form-rating-heading">
                                  <h6>
                                    Common fields of Agency and Organization
                                    recruiter type
                                  </h6>
                                </div>
                              </Col>

                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="organization_name">
                                    Organization Name:
                                  </label>
                                  <input
                                    type="text"
                                    id="organization_name"
                                    name="organization_name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.organization_name}
                                  />
                                  {formik.touched.organization_name &&
                                    formik.errors.organization_name && (
                                      <div>
                                        {formik.errors.organization_name}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="registration_number">
                                    Registration Number:
                                  </label>
                                  <input
                                    type="text"
                                    id="registration_number"
                                    name="registration_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.registration_number}
                                  />
                                  {formik.touched.registration_number &&
                                    formik.errors.registration_number && (
                                      <div>
                                        {formik.errors.registration_number}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={12}>
                                <div className="ip p-2">
                                  <label htmlFor="photo_logo">
                                    Photo Logo:
                                  </label>

                                  <input
                                    type="file"
                                    id="photo_logo"
                                    name="photo_logo"
                                    onChange={(event) => {
                                      formik.setFieldValue(
                                        "photo_logo",
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                  />
                                  {formik.touched.photo_logo &&
                                    formik.errors.photo_logo && (
                                      <div>{formik.errors.photo_logo}</div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="website">Website:</label>
                                  <input
                                    type="text"
                                    id="website"
                                    name="website"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.website}
                                  />
                                  {formik.touched.website &&
                                    formik.errors.website && (
                                      <div>{formik.errors.website}</div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="contact_number">
                                    Contact Number:
                                  </label>
                                  <input
                                    type="text"
                                    id="contact_number"
                                    name="contact_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.contact_number}
                                  />
                                  {formik.touched.contact_number &&
                                    formik.errors.contact_number && (
                                      <div>{formik.errors.contact_number}</div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="ip p-2">
                                  <label htmlFor="billing_address">
                                    Billing Address
                                  </label>
                                  <input
                                    type="text"
                                    id="billing_address"
                                    name="billing_address"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.billing_address}
                                  />
                                  {formik.touched.billing_address &&
                                    formik.errors.billing_address && (
                                      <div>{formik.errors.billing_address}</div>
                                    )}
                                </div>
                              </Col>
                              <Col xs={12} md={12}>
                                <div className="ip p-2">
                                  <button className="tf-btn-submit style-2">
                                    submit
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </form>
                        )}
                      </Formik>
                    </FormLabel>
                  </TabPanel>
                  <TabPanel className="inner-content animation-tab">
                    <h5>Full Job Description</h5>
                    <p>
                      Are you a User Experience Designer with a track record of
                      delivering intuitive digital experiences that drive
                      results? Are you a strategic storyteller and systems
                      thinker who can concept and craft smart, world-class
                      campaigns across a variety of mediums?
                    </p>
                  </TabPanel>
                  <TabPanel className="inner-content animation-tab">
                    <h5>Full Reviews</h5>
                    <p>
                      Are you a User Experience Designer with a track record of
                      delivering intuitive digital experiences that drive
                      results? Are you a strategic storyteller and systems
                      thinker who can concept and craft smart.
                    </p>
                  </TabPanel>
                </div>
              </Tabs>
            </div>
            <div className="col-lg-4">
              <div className="cv-form-details po-sticky job-sg single-stick">
                <MapSingle marKers={marKers} />
                <ul className="list-infor">
                  <li>
                    <div className="category">Website</div>
                    <div className="detail">
                      <Link to="https://themeforest.net/user/themesflat">
                        Themesflat.vn
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="category">Email</div>
                    <div className="detail">themesflat@gmail.com</div>
                  </li>
                  <li>
                    <div className="category">Industry</div>
                    <div className="detail">Internet Publishing</div>
                  </li>
                  <li>
                    <div className="category">Company size</div>
                    <div className="detail">51-200 employees</div>
                  </li>
                  <li>
                    <div className="category">Headquarters</div>
                    <div className="detail">3 S Valley , Las Vegas, USA</div>
                  </li>
                  <li>
                    <div className="category">Founded</div>
                    <div className="detail">2017</div>
                  </li>
                </ul>

                <div className="wd-social d-flex aln-center">
                  <span>Socials:</span>
                  <ul className="list-social d-flex aln-center">
                    <li>
                      <Link to="#">
                        <i className="icon-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icon-linkedin2"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icon-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icon-pinterest"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icon-instagram1"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icon-youtube"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="form-job-single">
                  <h6>Contact Us</h6>
                  <form action="post">
                    <input type="text" placeholder="Subject" />
                    <input type="text" placeholder="Name" />
                    <input type="email" placeholder="Email" />
                    <textarea placeholder="Message..."></textarea>
                    <button>Send Message</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Gotop />
    </>
  );
}

export default RecruiterProfile;
