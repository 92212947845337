import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SortBuy from "../dropdown/SortBuy";
import axios from "../../api/axios";

JobSec1.propTypes = {};
const Jobcountcategorywise_URL = "/jobs/categories/";
function JobSec1(props) {
  const { data } = props;
  const [jobCategories, setJobCategories] = useState([]); // Initialize as an empty array
  const [error, setError] = useState(null);
  const access = localStorage.getItem("access");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Jobcountcategorywise_URL, {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        });
        setJobCategories(response.data);
      } catch (error) {
        setError(`An error occurred: ${error.message}`);
      }
    };

    fetchData();
  }, [access]);
  return (
    <section className={props.className}>
    <div className="tf-container">
      <div className="row">
        <div className="col-md-12">
          <div className="tf-title">
            <div className="group-title">
              <h1>All Categories</h1>
              <p>Recruitment Made Easy in 100 seconds</p>
            </div>
           
          </div>
        </div>

        <div className="col-md-12">
          <div
            className="group-category-job wow fadeInUp"
            data-wow-delay=".2s"
          >
            {Array.isArray(jobCategories.payload) &&
            jobCategories.payload.length > 0 ? (
              jobCategories.payload.map((category) => (
                <div key={category.job_count} className="job-category-box">
                  <div className="job-category-header">
                    <h1>
                       <Link to={`/jobs_by_category/${category.title}`}> 
                        {category.title}
                      </Link>
                    </h1>
                    <p>{category.job_count} Jobs</p>
                  </div>
                {/*   <Link
                    to={`/jobsingle_v1/${category.title}`}
                    className="btn-category-job"
                  > */}
                  <Link
                    to={`/jobs_by_category/${category.title}`}
                    className="btn-category-job"
                  >
                    Explore Jobs{" "}
                    <span className="icon-keyboard_arrow_right"></span>
                  </Link>
                </div>
              ))
            ) : (
              <p>No categories available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default JobSec1;
