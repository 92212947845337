import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, FormLabel } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import JobSeekerAddProject from "./AddProject";
import ResumeTable from "./BuiltResume";
import JobSeekerAddCv from "./AddCV";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import JobSeekerEditProject from "./EditProject";
import JobSeekerAddExperience from "./AddExperience";
import JobSeekerEditExperience from "./EditExperience";
import JobSeekerAddEducation from "./AddEducation";
import JobSeekerEditEducation from "./EditEducation";
import SkillsTable from "./SkillsTable";
import JobSeekerAddSkills from "./AddSkills";
import LanguageTable from "./LanguageTable";
import JobSeekerAddLanguage from "./AddLanguage";

const RecruiterProfileDetails_URL = "/recruiter/recruiter_profile/";

EditJobseekerProfile.propTypes = {};

function EditJobseekerProfile(props) {
  const [profile_id, setprofileId] = useState("");
  const [formData, setFormData] = useState(null);
  const access = localStorage.getItem("access");
  const UserName = localStorage.getItem("username");
  console.log("UserName", UserName);

  const validationSchema = Yup.object().shape({
    // Define your form fields and their validation rules
    organization_name: Yup.string().required("Organization name is required"),
    registration_number: Yup.string().required(
      "Registration number is required"
    ),
  });

  const initialValues = {
    profile_id: "",
    organization_name: "",
    registration_number: "",
    logo: null,
    website: "",
    contact_number: "",
    entity_type: "",
    parent_org_name: "",
    num_employees: "",
    about: "",
    key_business_activities: "",
    industry_sector: "",
    org_address: "",
    parent_org_address: "",
    billing_address: "",
    primary_contact_name: "",
    primary_contact_email: "",
    primary_contact_tel: "",
    client_entity_type: "",
    client_org_name: "",
    client_num_employees: "",
    client_org_website: "",
    client_org_industry: "",
    client_org_address: "",
    client_primary_contact_email: "",
    client_primary_contact_phone: "",
    contract_copy_with_client: null,
    registration_number: "",
    photo_logo: null,
    website: "",
    contact_number: "",
    billing_address2: "",
  };

  const onSubmit = async (values) => {
    try {
      // Assuming you have an endpoint for updating data
      const response = await axios.patch(
        RecruiterProfileDetails_URL,
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "multipart/form-data",
          },
        },

        {
          organization_name: values.organization_name,
          registration_number: values.registration_number,
          logo: values.logo,
          website: values.website,
          contact_number: values.contact_number,
          entity_type: values.entity_type,
          parent_org_name: values.parent_org_name,
          num_employees: values.num_employees,
          about: values.about,
          key_business_activities: values.key_business_activities,
          industry_sector: values.industry_sector,
          org_address: values.org_address,
          parent_org_address: values.parent_org_address,
          billing_address: values.billing_address,
          primary_contact_name: values.primary_contact_name,
          primary_contact_email: values.primary_contact_email,
          primary_contact_tel: values.primary_contact_tel,
          client_entity_type: values.client_entity_type,
          client_org_name: values.client_org_name,
          client_num_employees: values.client_num_employees,
          client_org_website: values.client_org_website,
          client_org_industry: values.client_org_industry,
          client_org_address: values.client_org_address,
          client_primary_contact_email: values.client_primary_contact_email,
          client_primary_contact_phone: values.client_primary_contact_phone,
          photo_logo: values.photo_logo,
          billing_address2: values.billing_address2,
        }
      );
      // const profile_id = response.data.agency_profile_id;
      //setprofileId(profile_id);
      //localStorage.setItem("agency_profile_id", profile_id);
      //console.log("profile_id",profile_id)
      setFormData(values);
      // Handle the response as needed
      console.log(response.data);
      const profileId = response.data.organization_profile_id;
      setprofileId(profileId);
    } catch (error) {
      // Handle errors
      console.error("Error updating data:", error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      <FormLabel>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <div className="form-candidate">
                <Row>
                  <Col xs={12} md={12}>
                    <div className="form_heading">Account Information</div>
                  </Col>

                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="organization_name">Email:</label>
                      <input
                        type="text"
                        // placeholder="EX: Boston University"
                        id="organization_name"
                        name="organization_name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.organization_name}
                      />
                      {formik.touched.organization_name &&
                        formik.errors.organization_name && (
                          <div>{formik.errors.organization_name}</div>
                        )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="registration_number">Password:</label>
                      <input
                        type="text"
                        // placeholder="EX: Bachelor's"
                        id="registration_number"
                        name="registration_number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.registration_number}
                      />
                      {formik.touched.registration_number &&
                        formik.errors.registration_number && (
                          <div>{formik.errors.registration_number}</div>
                        )}
                    </div>
                  </Col>
                  <Col xs={12} md={12}>
                    <div className="form_heading">Personal Information</div>
                  </Col>
                  <Col xs={12} md={12}>
                    <div className="ip p-2">
                      <label htmlFor="logo">Profile Image:</label>

                      <input
                        type="file"
                        id="logo"
                        name="logo"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "logo",
                            event.currentTarget.files[0]
                          );
                          console.log("onChange", event);
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.logo && formik.errors.logo && (
                        <div>{formik.errors.logo}</div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={12}>
                    <div className="ip p-2">
                      <label htmlFor="logo">Cover Photo:</label>

                      <input
                        type="file"
                        id="logo"
                        name="logo"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "logo",
                            event.currentTarget.files[0]
                          );
                          console.log("onChange", event);
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.logo && formik.errors.logo && (
                        <div>{formik.errors.logo}</div>
                      )}
                    </div>
                  </Col>

                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="contact_number">First Name:</label>
                      <input
                        type="text"
                        id="contact_number"
                        name="contact_number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.contact_number}
                      />
                      {formik.touched.contact_number &&
                        formik.errors.contact_number && (
                          <div>{formik.errors.contact_number}</div>
                        )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="entity_type">Midlle Name:</label>
                      <input
                        type="text"
                        id="entity_type"
                        name="entity_type"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.entity_type}
                      />
                      {formik.touched.entity_type &&
                        formik.errors.entity_type && (
                          <div>{formik.errors.entity_type}</div>
                        )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="parent_org_name">Last Name:</label>
                      <input
                        type="text"
                        id="parent_org_name"
                        name="parent_org_name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.parent_org_name}
                      />
                      {formik.touched.parent_org_name &&
                        formik.errors.parent_org_name && (
                          <div>{formik.errors.parent_org_name}</div>
                        )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="num_employees">Father Name:</label>
                      <input
                        type="text"
                        id="num_employees"
                        name="num_employees"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.num_employees}
                      />
                      {formik.touched.num_employees &&
                        formik.errors.num_employees && (
                          <div>{formik.errors.num_employees}</div>
                        )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="education">Genter</label>
                      <select
                        id="education"
                        name="education"
                        value="" // Static value
                      >
                        <option value="" label="Select an option" />

                        <option value="1" label="Option 1" />
                        <option value="2" label="Option 2" />
                        <option value="3" label="Option 3" />
                      </select>

                      {formik.touched.title && formik.errors.title && (
                        <div style={{ color: "red" }}>
                          {formik.errors.title}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="education">Martial Status</label>
                      <select
                        id="education"
                        name="education"
                        value="" // Static value
                      >
                        <option value="" label="Select an option" />

                        <option value="1" label="Option 1" />
                        <option value="2" label="Option 2" />
                        <option value="3" label="Option 3" />
                      </select>

                      {formik.touched.title && formik.errors.title && (
                        <div style={{ color: "red" }}>
                          {formik.errors.title}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="education">Country</label>
                      <select
                        id="education"
                        name="education"
                        value="" // Static value
                      >
                        <option value="" label="Select an option" />

                        <option value="1" label="Option 1" />
                        <option value="2" label="Option 2" />
                        <option value="3" label="Option 3" />
                      </select>

                      {formik.touched.title && formik.errors.title && (
                        <div style={{ color: "red" }}>
                          {formik.errors.title}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="education">State</label>
                      <select
                        id="education"
                        name="education"
                        value="" // Static value
                      >
                        <option value="" label="Select an option" />

                        <option value="1" label="Option 1" />
                        <option value="2" label="Option 2" />
                        <option value="3" label="Option 3" />
                      </select>

                      {formik.touched.title && formik.errors.title && (
                        <div style={{ color: "red" }}>
                          {formik.errors.title}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="education">City</label>
                      <select
                        id="education"
                        name="education"
                        value="" // Static value
                      >
                        <option value="" label="Select an option" />

                        <option value="1" label="Option 1" />
                        <option value="2" label="Option 2" />
                        <option value="3" label="Option 3" />
                      </select>

                      {formik.touched.title && formik.errors.title && (
                        <div style={{ color: "red" }}>
                          {formik.errors.title}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="education">Nationality</label>
                      <select
                        id="education"
                        name="education"
                        value="" // Static value
                      >
                        <option value="" label="Select an option" />

                        <option value="1" label="Option 1" />
                        <option value="2" label="Option 2" />
                        <option value="3" label="Option 3" />
                      </select>

                      {formik.touched.title && formik.errors.title && (
                        <div style={{ color: "red" }}>
                          {formik.errors.title}
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="application_deadline">
                        Date of Birth
                      </label>
                      <input
                        type="date"
                        placeholder="EX: (YYYY-MM-DD)"
                        id="application_deadline"
                        name="application_deadline"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.application_deadline}
                      />
                      {formik.touched.application_deadline &&
                        formik.errors.application_deadline && (
                          <div style={{ color: "red" }}>
                            {formik.errors.application_deadline}
                          </div>
                        )}
                    </div>
                  </Col>

                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="key_business_activities">
                        National ID:
                      </label>
                      <input
                        type="text"
                        id="key_business_activities"
                        name="key_business_activities"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.key_business_activities}
                      />
                      {formik.touched.num_employees &&
                        formik.errors.num_employees && (
                          <div>{formik.errors.num_employees}</div>
                        )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="industry_sector">Phone:</label>
                      <input
                        type="text"
                        id="industry_sector"
                        name="industry_sector"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.industry_sector}
                      />
                      {formik.touched.industry_sector &&
                        formik.errors.industry_sector && (
                          <div>{formik.errors.industry_sector}</div>
                        )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="org_address">Mobile:</label>
                      <input
                        type="text"
                        id="org_address"
                        name="org_address"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.org_address}
                      />
                      {formik.touched.org_address &&
                        formik.errors.org_address && (
                          <div>{formik.errors.org_address}</div>
                        )}
                    </div>
                  </Col>
                  <Col xs={12} md={12}>
                    <div className="ip p-2">
                      <label htmlFor="about">Street Address:</label>
                      <Field
                        as="textarea"
                        id="about"
                        name="about"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.about}
                        rows="4" // Set the number of rows for the textarea
                      />
                      {formik.touched.about && formik.errors.about && (
                        <div>{formik.errors.about}</div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={12}>
                    <div className="form_heading">Add Video Profile</div>
                  </Col>
                  <Col xs={12} md={12}>
                    <div className="ip p-2">
                      <label htmlFor="about">
                        Video Link - sample:
                        https://www.youtube.com/embed/538cRSPrwZU
                      </label>
                      <Field
                        as="textarea"
                        id="about"
                        name="about"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.about}
                        rows="4" // Set the number of rows for the textarea
                      />
                      {formik.touched.about && formik.errors.about && (
                        <div>{formik.errors.about}</div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={12}>
                    <div className="form_heading">Career Information</div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="education">Job Experience:</label>
                      <select
                        id="education"
                        name="education"
                        value="" // Static value
                      >
                        <option value="" label="Select an option" />

                        <option value="1" label="Option 1" />
                        <option value="2" label="Option 2" />
                        <option value="3" label="Option 3" />
                      </select>

                      {formik.touched.title && formik.errors.title && (
                        <div style={{ color: "red" }}>
                          {formik.errors.title}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="education">Career Level:</label>
                      <select
                        id="education"
                        name="education"
                        value="" // Static value
                      >
                        <option value="" label="Select an option" />

                        <option value="1" label="Option 1" />
                        <option value="2" label="Option 2" />
                        <option value="3" label="Option 3" />
                      </select>

                      {formik.touched.title && formik.errors.title && (
                        <div style={{ color: "red" }}>
                          {formik.errors.title}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="education">Select Industry:</label>
                      <select
                        id="education"
                        name="education"
                        value="" // Static value
                      >
                        <option value="" label="Select an option" />

                        <option value="1" label="Option 1" />
                        <option value="2" label="Option 2" />
                        <option value="3" label="Option 3" />
                      </select>

                      {formik.touched.title && formik.errors.title && (
                        <div style={{ color: "red" }}>
                          {formik.errors.title}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="ip p-2">
                      <label htmlFor="education">Functional Area:</label>
                      <select
                        id="education"
                        name="education"
                        value="" // Static value
                      >
                        <option value="" label="Select an option" />

                        <option value="1" label="Option 1" />
                        <option value="2" label="Option 2" />
                        <option value="3" label="Option 3" />
                      </select>

                      {formik.touched.title && formik.errors.title && (
                        <div style={{ color: "red" }}>
                          {formik.errors.title}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="ip p-2">
                      <label htmlFor="billing_address">Current Salary:</label>
                      <input
                        type="text"
                        id="billing_address"
                        name="billing_address"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.billing_address}
                      />
                      {formik.touched.billing_address &&
                        formik.errors.billing_address && (
                          <div>{formik.errors.billing_address}</div>
                        )}
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="ip p-2">
                      <label htmlFor="primary_contact_name">
                        Expected Salary:
                      </label>
                      <input
                        type="text"
                        id="primary_contact_name"
                        name="primary_contact_name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.primary_contact_name}
                      />
                      {formik.touched.primary_contact_name &&
                        formik.errors.primary_contact_name && (
                          <div>{formik.errors.primary_contact_name}</div>
                        )}
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="ip p-2">
                      <label htmlFor="primary_contact_email">
                        Salary Currency:
                      </label>
                      <input
                        type="text"
                        id="primary_contact_email"
                        name="primary_contact_email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.primary_contact_email}
                      />
                      {formik.touched.primary_contact_email &&
                        formik.errors.primary_contact_email && (
                          <div>{formik.errors.primary_contact_email}</div>
                        )}
                    </div>
                  </Col>

                  <Col xs={12} md={12}>
                    <div className="ip p-2">
                      <button className="tf-btn-submit style-2">
                        UPDATE PROFILE AND SAVE
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="form-candidate mt-4">
                <Row>
                  <Col xs={12} md={12}>
                    <div className="form_heading">Summary:</div>
                  </Col>
                  <Col xs={12} md={12}>
                    <div className="ip p-2">
                      <label htmlFor="about"></label>
                      <Field
                        as="textarea"
                        id="about"
                        name="about"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.about}
                        rows="4" // Set the number of rows for the textarea
                      />
                      {formik.touched.about && formik.errors.about && (
                        <div>{formik.errors.about}</div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={12}>
                    <div className="ip p-2">
                      <button className="tf-btn-submit style-2">
                        UPDATE SUMMARY
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
          )}
        </Formik>
        <Col xs={12} md={12}>
          <h6 className="mt-4">Build Your Resume:</h6>
        </Col>
        <div className="form-candidate mt-4">
          <Row>
            <Col xs={12} md={12}>
              <div className="form_heading">Curriculum vitae:</div>
            </Col>
            <Col xs={12} md={12}>
              <div className="ip p-2">
                <ResumeTable />
              </div>
            </Col>
            <Col xs={12} md={12}>
              <div className="ip p-2">
                <JobSeekerAddCv />
              </div>
            </Col>
          </Row>
        </div>

        <div className="form-candidate mt-4">
          <Col xs={12} md={12}>
            <div className="form_heading">Projects:</div>
          </Col>
          <Col xs={12} md={12}>
            <div className="ip p-2">
              <div className="col-lg-4 wow fadeInUp">
                <div className="box-latest">
                  <div className="img-latest">
                    <img alt="Jobtex" />
                  </div>
                  <div className="box-content">
                    <div className="heading">
                      <Link to="#" className="tag">
                        rtyut
                      </Link>
                      <h3>
                        <Link to="blog-detail-side-bar.html"> plpoiop</Link>
                      </h3>
                    </div>
                    <ul className="date-post">
                      <li>
                        <JobSeekerEditProject />
                        {/* <button className="edit_btn">Edit</button> */}
                      </li>
                      <li>
                        <button className="edit_btn">
                          {" "}
                          <DeleteIcon fontSize="inherit" />
                        </button>
                        {/* <DeleteIcon fontSize="inherit" /> */}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <div className="ip p-2">
              <JobSeekerAddProject />
            </div>
          </Col>
        </div>
        <div className="form-candidate mt-4">
          <Col xs={12} md={12}>
            <div className="form_heading">Experience:</div>
          </Col>
          <Col xs={12} md={12}>
            <div className="ip p-2">
            <div  className="col-lg-6 ">
              <div className="features-job">
                <div className="job-archive-header">
                  <div className="inner-box">
                    
                    <div className="box-content">
                      <h4>
                        <Link to="/jobsingle_v1">prismatic</Link>
                      </h4>
                      <h3>
                        <Link to="/jobsingle_v1"> fullstack developer </Link>
                        <span className="icon-bolt"></span>
                      </h3>
                      <ul>
                        <li>
                        <JobSeekerEditExperience />
                        </li>
                        <li>
                        <button className="edit_btn">
                          {" "}
                          <DeleteIcon fontSize="inherit" />
                        </button>
                        </li>
                      </ul>
                      <span className="icon-heart"></span>
                    </div>
                  </div>
                </div>
                <div className="job-archive-footer">
                  <div className="job-footer-left">
                    <ul className="job-tag">
                      <li>
                      <Link to="#">ttyo</Link>
                      </li>
                      <li>
                        <Link to="#">iuo</Link>
                      </li>
                    </ul>
                    <div className="star">
                      <span className="icon-star-full"></span>
                      <span className="icon-star-full"></span>
                      <span className="icon-star-full"></span>
                      <span className="icon-star-full"></span>
                      <span className="icon-star-full"></span>
                    </div>
                  </div>
                  <div className="job-footer-right">
                    <div className="price">
                      <span className="icon-dolar1"></span>
                      <p>
                       123
                        <span className="year">/year</span>
                      </p>
                    </div>
                    <p className="days">98</p>
                  </div>
                </div>
                
              </div>
            </div>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <div className="ip p-2">
              <JobSeekerAddExperience />
            </div>
          </Col>
        </div>
        <div className="form-candidate mt-4">
          <Col xs={12} md={12}>
            <div className="form_heading">Education:</div>
          </Col>
          <Col xs={12} md={12}>
            <div className="ip p-2">
            <div  className="col-lg-6 ">
              <div className="features-job">
                <div className="job-archive-header">
                  <div className="inner-box">
                    
                    <div className="box-content">
                      <h4>
                        <Link to="/jobsingle_v1">prismatic</Link>
                      </h4>
                      <h3>
                        <Link to="/jobsingle_v1"> fullstack developer </Link>
                        <span className="icon-bolt"></span>
                      </h3>
                      <ul>
                        <li>
                        <JobSeekerEditEducation />
                        </li>
                        <li>
                        <button className="edit_btn">
                          {" "}
                          <DeleteIcon fontSize="inherit" />
                        </button>
                        </li>
                      </ul>
                      <span className="icon-heart"></span>
                    </div>
                  </div>
                </div>
                <div className="job-archive-footer">
                  <div className="job-footer-left">
                    <ul className="job-tag">
                      <li>
                      <Link to="#">ttyo</Link>
                      </li>
                      <li>
                        <Link to="#">iuo</Link>
                      </li>
                    </ul>
                    <div className="star">
                      <span className="icon-star-full"></span>
                      <span className="icon-star-full"></span>
                      <span className="icon-star-full"></span>
                      <span className="icon-star-full"></span>
                      <span className="icon-star-full"></span>
                    </div>
                  </div>
                  <div className="job-footer-right">
                    <div className="price">
                      <span className="icon-dolar1"></span>
                      <p>
                       123
                        <span className="year">/year</span>
                      </p>
                    </div>
                    <p className="days">98</p>
                  </div>
                </div>
                
              </div>
            </div>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <div className="ip p-2">
              <JobSeekerAddEducation />
            </div>
          </Col>
        </div>
        <div className="form-candidate mt-4">
          <Row>
            <Col xs={12} md={12}>
              <div className="form_heading">Skills:</div>
            </Col>
            <Col xs={12} md={12}>
              <div className="ip p-2">
                <SkillsTable />
              </div>
            </Col>
            <Col xs={12} md={12}>
              <div className="ip p-2">
                <JobSeekerAddSkills />
              </div>
            </Col>
          </Row>
        </div>
        <div className="form-candidate mt-4">
          <Row>
            <Col xs={12} md={12}>
              <div className="form_heading">Languages:</div>
            </Col>
            <Col xs={12} md={12}>
              <div className="ip p-2">
                <LanguageTable />
              </div>
            </Col>
            <Col xs={12} md={12}>
              <div className="ip p-2">
                <JobSeekerAddLanguage />
              </div>
            </Col>
          </Row>
        </div>
      </FormLabel>
    </>
  );
}

export default EditJobseekerProfile;
