/* import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
//import { BrowserRouter } from "react-router-dom";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { AuthProvider } from "./context/AuthProvider"
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </AuthProvider>
  </BrowserRouter>
</React.StrictMode>
); */
/* 
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { AuthProvider } from "./context/AuthProvider"
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
); */

// index.js
/* import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { AuthProvider} from './context/AuthContext';
ReactDOM.render(
  <React.StrictMode>
   
      <AuthProvider>
        <App />
      </AuthProvider>
   
  </React.StrictMode>,
  document.getElementById('root')
); */

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { AuthProvider } from "./context/AuthProvider"
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faIconName } from '@fortawesome/free-solid-svg-icons';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
