import React from 'react';
import PropTypes from 'prop-types';

Employerdashboard.propTypes = {
    
};

function Employerdashboard(props) {
    return (
        <div>
            
        </div>
    );
}

export default Employerdashboard;