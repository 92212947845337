import React from 'react';
import PropTypes from 'prop-types';
//import { useNavigate } from "react-router-dom"
Page404.propTypes = {
    
};

function Page404(props) {
 /*    const navigate = useNavigate();

    const goBack = () => navigate(-1); */
    return (
        <section>
        <h1>Unauthorized</h1>
        <br />
        <p>You do not have access to the requested page.</p>
       {/*  <div className="flexGrow">
            <button onClick={goBack}>Go Back</button>
        </div> */}
    </section>
    );
}

export default Page404;