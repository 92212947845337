// axios.js
import axios from "axios";

const instance = axios.create({
  baseURL: "https://api.myzonehub.com",
});

// Request interceptor to add Authorization header
instance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

// Response interceptor to handle token expiration and refresh
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is due to an expired access token
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Attempt to refresh the access token using the refresh token
        const refreshToken = localStorage.getItem("refreshToken");
        const refreshResponse = await axios.post("https://api.myzonehub.com/api/token/refresh/", {
          refresh: refreshToken,
        });

        // If refresh is successful, update the access token and retry the original request
        const newAccessToken = refreshResponse.data.access;
        localStorage.setItem("accessToken", newAccessToken);
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axios(originalRequest);
      } catch (refreshError) {
        // If refresh fails, prompt the user to log in again or handle the scenario accordingly
        console.error("Refresh token failed or expired", refreshError);
        // handleLogout(); // Implement a function to handle logout
        // redirect to login page
       // window.location.href = "/login";
       // return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
