/* // useAuth.js
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

const useAuth = () => {
    return useContext(AuthContext);
}

export default useAuth; // Export as default
 */
// useAuth.js
import { useContext, useEffect } from "react";
import AuthContext from "../context/AuthProvider";

const useAuth = () => {
    const { auth, setAuth } = useContext(AuthContext);
   /*  useEffect(() => {
        const storedAuth = localStorage.getItem("auth");
        if (storedAuth) {
            setAuth(JSON.parse(storedAuth));
        }
        console.log(storedAuth,"storedAuth")
    }, [setAuth]); */
    

    return { auth, setAuth };
}

export default useAuth;
