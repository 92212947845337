import React from "react";
import PropTypes from "prop-types";
import Footer from "../../components/footer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Progress from "../../components/progressBar/DonutProgress";
import Gotop from "../../components/gotop";
import Video from "../../components/popup/Video";
import Gallery from "../../components/popup/Gallery";
import { Collapse } from "react-collapse";
import logo from "../../assets/images/logo.png";
import Header4 from "../../components/header/Header4";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPrint,
  faChartBar,
  faEye,
  faComputer,
  faHeart,
  faExclamationTriangle,
  faFile,
  faMessage,
  faUser,
  faSignOutAlt,
  faBriefcase,
  faList
} from "@fortawesome/free-solid-svg-icons";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SideMenu from "../../components/jobseeker/myjobapplication/SideMenu";

JobSeekerDashboard.propTypes = {};

function JobSeekerDashboard(props) {
  const UserName = localStorage.getItem("username");
  const progressRef = useRef();
  const [targetHeight, setTargetHeight] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  useEffect(() => {
    if (progressRef?.current) {
      const offsetHeight = progressRef?.current?.offsetTop;
      setTargetHeight(offsetHeight);
    }
  }, [progressRef]);

 


  const handleClick = () => {
    navigate("/jobseeker/my-profile");
   
  };

  return (
    <>
      <div className="menu-mobile-popup">
        <div className="modal-menu__backdrop" onClick={handleMobile}></div>
        <div className="widget-filter">
          <div className="mobile-header">
            <div id="logo" className="logo">
              <Link to="/">
                <img className="site-logo" src={logo} alt="Image" />
              </Link>
            </div>
            <Link className="title-button-group" onClick={handleMobile}>
              <i className="icon-close"></i>
            </Link>
          </div>

          <Tabs className="tf-tab">
            <TabList className="menu-tab">
              <Tab className="user-tag">Menu</Tab>
              <Tab className="user-tag">Categories</Tab>
            </TabList>

            <div className="content-tab">
              <TabPanel className="header-ct-center menu-moblie animation-tab">
                <div className="nav-wrap">
                  <nav className="main-nav mobile">
                    <ul id="menu-primary-menu" className="menu">
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("home");
                          }}
                        >
                          Home
                        </Link>
                        <Collapse isOpened={toggle.key === "home"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "home" ? "block" : "none"
                              }`,
                            }}
                          >
                            <li className="menu-item menu-item-mobile">
                              <Link to="/">Home Page 01 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v2">Home Page 02 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v3">Home Page 03 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v4">Home Page 04 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v5">Home Page 05 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v6">Home Page 06 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v7">Home Page 07 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v8">Home Page 08 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v9">Home Page 09 </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/home_v10">Home Page 10 </Link>
                            </li>
                          </ul>
                        </Collapse>
                      </li>

                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("job");
                          }}
                        >
                          Find jobs
                        </Link>
                        <Collapse isOpened={toggle.key === "job"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "job" ? "block" : "none"
                              }`,
                            }}
                          >
                            <li className="menu-item menu-item-mobile">
                              <Link to="/joblist_v1">List Layout</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/job-grid">Grid Layout</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/job-list-sidebar">List Sidebar</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/job-grid-sidebar">Grid Sidebar</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/joblist_v5">
                                List Sidebar Fullwidth
                              </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/joblist_v6">
                                Grid Sidebar Fullwidth
                              </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/joblist_v7">Top Map</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/joblist_v8">Top Map Sidebar</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/joblist_v9">Half Map - V1</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/joblist_v10">Half Map - V2</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/jobsingle_v1">Jobs Single - V1</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/jobsingle_v2">Jobs Single - V2</Link>
                            </li>
                          </ul>
                        </Collapse>
                      </li>

                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("employers");
                          }}
                        >
                          Employers
                        </Link>
                        <Collapse isOpened={toggle.key === "employers"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "employers" ? "block" : "none"
                              }`,
                            }}
                          >
                            <li className="menu-item">
                              <Link to="/employers_v1">List Layout</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/employers_v2">Grid Layout</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/employers_v3">List Sidebar</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/employers_v4">Grid Sidebar</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/employers_v5">Full Width</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/employers_v6">Top Map</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/employers_v7">Half Map</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/employersingle_v1">
                                Employers Single - V1
                              </Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/employersingle_v2">
                                Employers Single - V2
                              </Link>
                            </li>

                            <li className="menu-item">
                              <Link to="/employerreview">
                                Employers Reviews
                              </Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/employernotfound">
                                Employers Not Found
                              </Link>
                            </li>
                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile current-item">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("candidate");
                          }}
                        >
                          Candidates
                        </Link>
                        <Collapse isOpened={toggle.key === "candidate"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "candidate" ? "block" : "none"
                              }`,
                            }}
                          >
                            <li className="menu-item menu-item-mobile">
                              <Link to="/candidates_v1">List Layout</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/candidates_v2">Grid Layout</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/candidates_v3">List Sidebar</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/candidates_v4">Top Map</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/candidates_v5">Half Map</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/candidates_v6">No Available V1</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/candidates_v7">No Available V2</Link>
                            </li>
                            <li className="menu-item menu-item-mobile current-item">
                              <Link to="/candidatesingle_v1">
                                Candidate Single - V1
                              </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/candidatesingle_v2">
                                Candidate Single - V2
                              </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/samplecv">Sample CV</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/samplecvslidebar">
                                Sample CV Sidebar
                              </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/samplecvdetails">CV Details</Link>
                            </li>
                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("blog");
                          }}
                        >
                          Blog
                        </Link>
                        <Collapse isOpened={toggle.key === "blog"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "blog" ? "block" : "none"
                              }`,
                            }}
                          >
                            <li className="menu-item menu-item-mobile">
                              <Link to="/blog_v1">Blog List </Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/blog_v2">Blog Grid</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/blog_v3">Blog Masonry</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/blogsingle_v1">Blog Details - V1</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/blogsingle_v2">Blog Details - V2</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/blogsingle_v3">
                                Blog Details Sidebar
                              </Link>
                            </li>
                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("pages");
                          }}
                        >
                          Pages
                        </Link>
                        <Collapse isOpened={toggle.key === "pages"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "pages" ? "block" : "none"
                              }`,
                            }}
                          >
                            <li className="menu-item menu-item-mobile">
                              <Link to="/aboutus">About Us</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/faqs">FAQS</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/termsofuse">Terms Of Use</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/pricing">Pricing</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/shop">Shop List</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/shoppingcart">Shopping Cart</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/shopsingle">Shop Single</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/checkout">Checkout</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/login">Login</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/createaccount">Create Account</Link>
                            </li>
                            <li className="menu-item menu-item-mobile">
                              <Link to="/contactus">Contact Us</Link>
                            </li>
                          </ul>
                        </Collapse>
                      </li>
                    </ul>
                  </nav>
                </div>
              </TabPanel>

              <TabPanel className="categories animation-tab">
                <div className="sub-categorie-mobile">
                  <ul className="pop-up">
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-1"></span>Design &
                        Creative
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-8"></span>Digital
                        Marketing
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-2"></span>Development &
                        IT
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-3"></span>Music & Audio
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-4"></span>Finance &
                        Accounting
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-5"></span>Programming &
                        Tech
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-6"></span>Video &
                        Animation
                      </Link>
                    </li>
                    <li className="categories-mobile">
                      <Link to="/jobsingle_v1">
                        <span className="icon-categorie-7"></span>Writing &
                        translation
                      </Link>
                    </li>
                  </ul>
                </div>
              </TabPanel>
            </div>
          </Tabs>

          <div className="header-customize-item button">
            <Link to="/">Upload Resume</Link>
          </div>

          <div className="mobile-footer">
            <div className="icon-infor d-flex aln-center">
              <div className="icon">
                <span className="icon-call-calling">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </span>
              </div>
              <div className="content">
                <p>Need help? 24/7</p>
                <h6>
                  <Link to="tel:0123456678">001-1234-88888</Link>
                </h6>
              </div>
            </div>
            <div className="wd-social d-flex aln-center">
              <ul className="list-social d-flex aln-center">
                <li>
                  <Link to="#">
                    <i className="icon-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-linkedin2"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-pinterest"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-instagram1"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-youtube"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Header4 clname="actCan3" handleMobile={handleMobile} />

      <section className="wrapper-author-page-title style2 stc form-sticky fixed-space">
        <div className="tf-container">
          <div className="wd-author-page-title">
            <div className="author-archive-header">
              <img
                src={require("../../assets/images/user/avatar/avt-author-1.jpg")}
                alt="jobtex"
                className="logo-company"
              />
              <div className="content">
                <Link to="#" className="tag-head">
                  Available now
                </Link>
                <h4>
                  <Link to="#">Computer Systems Analyst</Link>
                </h4>
                <h3>
                  <Link to="#">{UserName}</Link>
                  <span className="icon-bolt"></span>
                </h3>
                <ul className="author-list">
                  <li className="tag"> Blender </li>
                  <li className="tag"> Sketch </li>
                  <li className="tag"> Adobe XD </li>
                  <li className="tag"> Figma </li>
                  <li className="map">
                    <span className="icon-map-pin"></span>&nbsp; Tokyo, Japan
                  </li>
                  <li className="price">
                    <span className="icon-dolar1"></span>&nbsp; $300/day
                  </li>
                </ul>
              </div>
            </div>
            <div className="author-archive-footer">
              <button className="tf-btn btn-popup" onClick={handleClick}>
                {" "}
                <FontAwesomeIcon icon={faEdit} />
                Edit Profile
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="candidates-section">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-4">
<SideMenu />
             {/*  <div className="cv-form-details stc po-sticky job-sg">
                <ul className="list-infor">
                  <li style={listStyle}>
                    <FontAwesomeIcon icon={faChartBar} />
                    <Link to="/jobseeker/dashboard" className="tag-head">
                      Dashboard
                    </Link>
                  </li>
                  <li style={listStyle}>
                    <FontAwesomeIcon icon={faEdit} />
                    <Link to="/jobseeker/my-profile" className="tag-head">
                      Edit Profile
                    </Link>
                  </li>
                  <li style={listStyle}>
                    <FontAwesomeIcon icon={faPrint} />
                    <Link to="#" className="tag-head">
                      Print Resume
                    </Link>
                  </li>
                  <li style={listStyle}>
                    <FontAwesomeIcon icon={faEye} />
                    <Link to="/jobseeker/view-public-profile" className="tag-head">
                      View Public Profile
                    </Link>
                  </li>
                  <li style={listStyle}>
                    <FontAwesomeIcon icon={faComputer} />
                    <Link to="/jobseeker/my-job-application" className="tag-head">
                      My Job Applications
                    </Link>
                  </li>
                  <li style={listStyle}>
                    <FontAwesomeIcon icon={faHeart} />
                    <Link to="/jobseeker/my-favourite-jobs" className="tag-head">
                      My Favourite Jobs
                    </Link>
                  </li>
                  <li style={listStyle}>
                    <FontAwesomeIcon icon={faBriefcase} />
                    <Link to="/jobseeker/my-alerts" className="tag-head">
                      My Job Alerts
                    </Link>
                  </li>
                  <li style={listStyle}>
                    <FontAwesomeIcon icon={faFile} />
                    <Link to="#" className="tag-head">
                      Manage Resume
                    </Link>
                  </li>
                  <li style={listStyle}>
                    <FontAwesomeIcon icon={faMessage} />
                    <Link to="#" className="tag-head">
                      My Messages
                    </Link>
                  </li>
                  <li style={listStyle}>
                    <FontAwesomeIcon icon={faUser} />
                    <Link to="/jobseeker/my-followings" className="tag-head">
                      My Followings
                    </Link>
                  </li>
                 
                </ul>
              </div> */}

            </div>
            <div className="col-lg-8">
              <>
                <Row className="card_flex">
                  <Col xs={6} md={2}>
                    <Card
                      bg="success"
                      text="white"
                      style={{ width: "10rem" }}
                      className="mb-2 mt-2"
                    >
                      <Card.Header className="card_center">
                        Profile View
                      </Card.Header>
                      <Card.Body>
                        <Card.Title className="card_center">
                          {" "}
                          <FontAwesomeIcon icon={faEye} />
                        </Card.Title>
                        <Card.Text className="card_center card_num_font">1</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col xs={6} md={2}>
                    <Card
                      bg="success"
                      text="white"
                      style={{ width: "10rem" }}
                      className="mb-2 mt-2"
                    >
                      <Card.Header className="card_center">
                        Followings
                      </Card.Header>
                      <Card.Body>
                        <Card.Title className="card_center">
                          {" "}
                          <FontAwesomeIcon icon={faUser} />
                        </Card.Title>
                        <Card.Text className="card_center card_num_font">876</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col xs={6} md={2}>
                    <Card
                      bg="success"
                      text="white"
                      style={{ width: "10rem" }}
                      className="mb-2 mt-2 "
                    >
                      <Card.Header className="card_center">
                        My CV List
                      </Card.Header>
                      <Card.Body>
                        <Card.Title className="card_center">
                          <FontAwesomeIcon icon={faBriefcase} />
                        </Card.Title>
                        <Card.Text className="card_center card_num_font">8</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col xs={6} md={2}>
                    <Card
                      bg="success"
                      text="white"
                      style={{ width: "10rem" }}
                      className="mb-2 mt-2 "
                    >
                      <Card.Header className="card_center">
                        Messages
                      </Card.Header>
                      <Card.Body>
                        <Card.Title className="card_center">
                          {" "}
                          <FontAwesomeIcon icon={faMessage} />
                        </Card.Title>
                        <Card.Text className="card_center card_num_font">56</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={8}>
                    {" "}
                    <Card className="mt-5">
                      <Card.Header>
                        {" "}
                        <h6>
                          {" "}
                          <FontAwesomeIcon icon={faBriefcase} /> Recommended
                          Jobs
                        </h6>
                      </Card.Header>
                      <Card.Body>
                        <Card.Title className="card_color">
                          Frontend Developer
                        </Card.Title>
                        <Card.Text>sphere</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={12} md={3}>
                    <Card style={{ width: "20rem" }} className="mt-5">
                      <Card.Header>
                        {" "}
                        <h6>
                          {" "}
                          <FontAwesomeIcon icon={faUser} /> My Followings
                        </h6>
                      </Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item >
                          <div className="d-flex">
                            <div>
                              <div><b className="card_font">Kushi</b></div>
                              <div><i>34/15,padmanabapuram,thuckalay</i></div>
                              <div><Link className="tag">View Details</Link></div>
                            </div>
                          
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item >
                          <div className="d-flex">
                            <div>
                              <div><b className="card_font">Kushi</b></div>
                              <div><em>34/15,padmanabapuram,thuckalay</em></div>
                              <div><Link className="tag">View Details</Link></div>
                            </div>
                          
                          </div>
                        </ListGroup.Item>
                       
                        <ListGroup.Item><FontAwesomeIcon icon={faList} /> <Link className="tag">View All</Link></ListGroup.Item>
                      </ListGroup>
                    </Card>
                  </Col>
                </Row>
              </>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Gotop />
    </>
  );
}

export default JobSeekerDashboard;
