/*  import { createContext, useState } from "react";
const AuthContext = createContext({});

export const AuthProvider = ({ children, email }) => {

  const [auth, setAuth] = useState({
    email: localStorage.getItem("email") || "",
    // other authentication properties
  });
 
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;  */

/* import { createContext, useState, useEffect } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: null,
    roles: [], // Initialize roles as an empty array
    // other authentication properties
  });

  useEffect(() => {
    // Retrieve user role from localStorage and parse JSON string
    const userRolesString = localStorage.getItem("userRole");
    const userDataString = localStorage.getItem("userData");
    
    if (userRolesString && userDataString) {
      const userRoles = JSON.parse(userRolesString);
      const userData = JSON.parse(userDataString);

      setAuth({
        user: userData,
        roles: userRoles,
        // other authentication properties
      });
    }
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
 */
import { createContext, useState, useEffect } from "react";

const AuthContext = createContext({});
let auth_storage = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) :"";
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(auth_storage);
  useEffect(() => {
    const storedAuth = localStorage.getItem("auth");
    if (storedAuth) {
      setAuth(JSON.parse(storedAuth));
    }
    console.log(storedAuth, "storedAuth");
  }, [setAuth]);
  console.log(auth, "auth");
  console.log("typeofauthinauthprovider", typeof(auth));

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

/* import { createContext, useState, useEffect } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children, email, username }) => {
  const [auth, setAuth] = useState({
    email: email || localStorage.getItem("email") || "",
    username: username || localStorage.getItem("userName") || "",

  });

  
  useEffect(() => {
    setAuth((prevAuth) => ({ ...prevAuth, email }));
  }, [email]);
  useEffect(() => {
    setAuth((prevAuth) => ({ ...prevAuth, username }));
  }, [username]);
 
  useEffect(() => {
    console.log("email", email);
    console.log("localStorage.getItem('email')", localStorage.getItem("email"));
  }, [email]);
  useEffect(() => {
    console.log("userName", username);
    console.log(
      "localStorage.getItem('userName')",
      localStorage.getItem("userName")
    );
  }, [username]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext; */
