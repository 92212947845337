import img1 from '../images/review/cv1.jpg'
import img2 from '../images/review/cv2.jpg'
import img3 from '../images/review/cv3.jpg'
import img4 from '../images/review/cv4.jpg'
import img5 from '../images/review/cv5.jpg'





const dataSample = [
    {
        id: 1,
        img: img1,
    },
    {
        id: 2,
        img: img2,
    },
    {
        id: 3,
        img: img3,
    },
    {
        id: 4,
        img: img4,
    },
    {
        id: 5,
        img: img5,
    },
    {
        id: 6,
        img: img1,
    },
    {
        id: 7,
        img: img2,
    },
    {
        id: 8,
        img: img3,
    },
    {
        id: 9,
        img: img4,
    },
    {
        id: 10,
        img: img5,
    },
    {
        id: 11,
        img: img1,
    },
    {
        id: 12,
        img: img2,
    },


]

export default dataSample;